
export const getTransformedPoint = (event, svg) => {
  const pt = svg.createSVGPoint();
  pt.x = getX(event);
  pt.y = getY(event);

  // Obtenir la matrice de transformation inverse du groupe <g>
  const g = svg.querySelector('g');
  const matrix = g.getCTM().inverse();

  // Appliquer la matrice inverse aux coordonnées du curseur
  const cursorpt = pt.matrixTransform(matrix);
  return [cursorpt.x, cursorpt.y];
};

export const getX = (event) => {
  return event?.sourceEvent?.offsetX ?? event?.offsetX ?? event?.x;
}

export const getY = (event) => {
  return event?.sourceEvent?.offsetY ?? event?.offsetY ?? event?.y;
}