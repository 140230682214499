import { getTransformedPoint } from './coordinates'

// fonction pour calculer la taille réelle de l'outil
export const getScaledSize = (baseSize, state) => {
  
  return baseSize * Scale(state)
}

// Version qui fonctionne pour l'outil zoom
export const zoom = (event, state, facteur) => {
  const svg = state.svg.node()
  
  // Récupérer le viewBox actuel
  const [currentX, currentY, currentWidth, currentHeight] = svg.getAttribute('viewBox')?.split(' ').map(Number) ?? 
    [0, 0, svg.clientWidth, svg.clientHeight]
  
  // Position du curseur dans les coordonnées SVG
  const transformedPoint = getTransformedPoint(event, state.svg.node())
  const pointX = transformedPoint[0]
  const pointY = transformedPoint[1]
  // Calculer les nouvelles dimensions
  const newWidth = currentWidth / facteur
  const newHeight = currentHeight / facteur

  // Calculer les nouveaux x,y pour garder le point de zoom au centre
  const newX = pointX - (newWidth / currentWidth) * (pointX - currentX)
  const newY = pointY - (newHeight / currentHeight) * (pointY - currentY)

  // Appliquer le nouveau viewBox
  svg.setAttribute('viewBox', `${newX} ${newY} ${newWidth} ${newHeight}`)
}

// Version adaptée pour la roulette
export const wheelZoom = (event, state) => {
  const facteur = event.deltaY < 0 ? 1.1 : 0.9
  zoom(event, state, facteur)
}



export const TailleOutilZoom = (baseSize, state) => {
  return baseSize * Scale(state)
}


export const Scale = (state) => {
  const viewBox = state.svg.node().getAttribute('viewBox')
  if (!viewBox) return 1
  
  const [, , vbWidth] = viewBox.split(' ').map(Number)
  const realWidth = state.svg.node().getBoundingClientRect().width
  return vbWidth / realWidth
}