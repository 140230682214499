<template>
  
  <div class="tbi-container">
    <MenuTBI 
      @action="ActionMenu" 
      :couleur="couleur" 
      :taille="taille" 
      :outil="outil"
      :surligner="surligner"
      :dernierTableau="dernierTableau"
      :premierTableau="premierTableau"
      :canredo="$refs?.whiteboard?.redoStack?.length ?? false" 
      :canundo="$refs?.whiteboard?.undoStack?.length ?? false"
      :tableauModifié="tableauModifié"
    />
    <div 
      class="tableau"
      @dragover.prevent
      @drop.prevent="handleDrop"
    >
      <vue-whiteboard
        class="whiteboard"
        :color="couleur"
        :taille_index="taille"
        ref="whiteboard"
        :outil="outil"
        :size="taille_outil_px"
        :surligner="surligner"
        :tableauActuel="TableauxBlancs?.[uuid_actuel] ?? null"
      />
    </div>
  </div>
</template>

<script>
import VueWhiteboard from '@/components/TBI/vue-whiteboard.vue'
import MenuTBI from '@/components/TBI/MenuTBI.vue'
import { mapState, mapMutations } from 'vuex';
import { Télécharge } from '@/components/TBI/utils/save'
import { CopierColler, NouveauFichierDragdrop } from '@/components/TBI/utils/fichiers'
import { v4 as uuidv4 } from 'uuid'


export default {
  name: 'TBI',
  emits: ['close'],
  props: {
  },
  
  data() {
    return {
      couleur: '#212121',
      surligner: false,
      taille: 1,
      outil: 'stylo',
      TailleGomme: [15, 40, 75, 128], // petit, moyen, gros, très gros
      TailleTrait: [3, 15, 30, 75],    // petit, moyen, gros, très gros
      TailleTexte: [20, 32, 50, 75],    // petit, moyen, gros, très gros
      currentScale: 1,
      uuid_actuel: null
    }
  },
  
  computed: {
    ...mapState(['TableauxBlancs','éditeurs','TableauxBlancsASupprimer']),
    taille_outil_px() {
      let baseSize;
      switch(this.outil) {
        case 'gomme':
          baseSize = this.TailleGomme[this.taille - 1]
          break
        case 'texte':
          baseSize = this.TailleTexte[this.taille - 1]
          break
        default:
          baseSize = this.TailleTrait[this.taille - 1]
      }
      
      return baseSize / this.currentScale
    },
    premierTableau() {
      return Object.keys(this.TableauxBlancs).at(0) == this.uuid_actuel
    },
    dernierTableau() {
      return Object.keys(this.TableauxBlancs).at(-1) == this.uuid_actuel
    },
    tableauModifié() {
      return this.TableauxBlancs?.[this.uuid_actuel]?.image || this.TableauxBlancs?.[this.uuid_actuel]?.historique?.currentSnapshot || this.TableauxBlancs?.[this.uuid_actuel]?.historique?.undoStack?.length
    }
  },

  components: {
    VueWhiteboard,
    MenuTBI
  },

  watch: {
   TableauxBlancsASupprimer() {
      if (this.TableauxBlancsASupprimer.length) {
        for (let uuid of this.TableauxBlancsASupprimer) {
          this.supprimerTableau(uuid)
        }
        this.updateTableauxBlancsASupprimer([])
      }
    },
  },

  mounted() {
    // Initialiser le curseur au montage du composant
    this.$nextTick(() => {
      if (this.$refs.whiteboard) {
        this.ActionMenu({name: 'tableau', value: 'nouveau' })
        this.$refs.whiteboard.$el.style.cursor = this.createCursor(this.taille_outil_px, this.couleur, this.surligner)
      }
    })
    
    // Passer this.ActionMenu en plus
    window.addEventListener('paste', (e) => CopierColler(e, this.$buefy, this.ActionMenu, this.$emit))
    // Ajouter l'écouteur pour les touches clavier
    window.addEventListener('keydown', this.handleKeyDown)
  },

  beforeUnmount() {
    window.removeEventListener('paste', (e) => CopierColler(e, this.$buefy, this.ActionMenu, this.$emit))
    // Retirer l'écouteur
    window.removeEventListener('keydown', this.handleKeyDown)
  },

  methods: {
    ...mapMutations(['updateTableauxBlancs', 'updateCommandeEditeur','updateTableauxBlancsASupprimer']),
    
    createCursor(size, color) {
      if (this.outil === 'texte') {
        return 'text'
      }
      if (this.outil === 'déplacer') {
        return 'grab'
      }
      if (this.outil === 'zoom+') {
        return 'zoom-in'
      }
      if (this.outil === 'zoom-') {
        return 'zoom-out'
      }
      const cursorSize = size * this.currentScale<8 ? 8 : size* this.currentScale
      const canvas = document.createElement('canvas')
      canvas.width = cursorSize
      canvas.height = cursorSize
      const ctx = canvas.getContext('2d')
      
      ctx.beginPath()
      ctx.arc(cursorSize/2, cursorSize/2, cursorSize/2 - 1, 0, 2 * Math.PI)
      
      if (this.outil === 'gomme') {
        // Curseur gomme : cercle pointillé noir
        ctx.setLineDash([2, 2])
        ctx.strokeStyle = '#000000'
        ctx.stroke()
      } else {
        // Curseur coloré : remplissage plein et contour de la même couleur
        ctx.fillStyle = color
        ctx.globalAlpha = this.surligner ? 0.5 : 1
        ctx.fill()
      }
      
      return `url(${canvas.toDataURL()}) ${cursorSize/2} ${cursorSize/2}, auto`
    },

    ActionMenu(event) {
      switch (event.name) {
        case 'nouvelleImage':
          if (this.tableauModifié)
          {
            this.enregistre()
            this.ActionMenu({name: 'tableau', value: 'nouveau', image: event.image, largeur: event.largeur, hauteur: event.hauteur})
          }
          else
          {
            const ancien_uuid = this.uuid_actuel
            this.enregistre()
            this.ActionMenu({name: 'tableau', value: 'nouveau', image: event.image, largeur: event.largeur, hauteur: event.hauteur})
            this.supprimerTableau(ancien_uuid)
          }
          // Pour que l'image soit bien enregistrée dans le SVG
          // mais il faut attendre qu'elle soit bien affichée
          this.$nextTick(() => {
            this.enregistre()
          })
          break;
        case 'couleur':
          this.couleur = event.value
          this.$refs.whiteboard.$el.style.cursor = this.createCursor(this.taille_outil_px, this.couleur, this.surligner)
          break
        case 'surligner':
          this.surligner = !this.surligner
          this.$refs.whiteboard.$el.style.cursor = this.createCursor(this.taille_outil_px, this.couleur, this.surligner)
          break
        case 'taille':
          this.taille = event.value
          this.$refs.whiteboard.$el.style.cursor = this.createCursor(this.taille_outil_px, this.couleur, this.surligner)
          break
        case 'outil':
          this.outil = event.value
          this.$refs.whiteboard.$el.style.cursor = this.createCursor(this.taille_outil_px, this.couleur, this.surligner)
          break
        case 'undo':
          this.$refs.whiteboard.undo()
          break
        case 'redo':
          this.$refs.whiteboard.redo()
          break
        case 'fermer':
          this.enregistre()
          this.$emit('close')
          break
        case 'télécharger':
          Télécharge(this.$refs.whiteboard.save(this.uuid_actuel))
          break
        case 'insérer':
          this.enregistre()
          this.updateCommandeEditeur({commande:"insérerTBI", data: {liste: [this.TableauxBlancs[this.uuid_actuel].svg], fin: true, supprimer: true}});
          this.supprimerTableau(this.uuid_actuel)
          this.$emit('close')
          break
        case 'tableau':
          this.enregistre()
          let liste_uuids
          let ancien_index
          
          switch (event.value) {
            case 'supprimer':
                this.$buefy.dialog.confirm({
                title: 'Effacer le tableau',
                message: 'Voulez-vous vraiment SUPPRIMER ce tableau ?',
                confirmText: 'Effacer',
                cancelText: 'Annuler',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                  this.supprimerTableau(this.uuid_actuel)
                }
              })
              break;
            case 'suivant':
              liste_uuids = Object.keys(this.TableauxBlancs)
              ancien_index = _.findIndex(liste_uuids, uuid => uuid == this.uuid_actuel)
              this.uuid_actuel = liste_uuids?.[ancien_index + 1] ?? undefined
              if (this.uuid_actuel)
                break
            case 'précédent':
              liste_uuids = Object.keys(this.TableauxBlancs)
              ancien_index = _.findIndex(liste_uuids, uuid => uuid == this.uuid_actuel)
              this.uuid_actuel = liste_uuids?.[ancien_index - 1] ?? undefined
              if (this.uuid_actuel)
                break
            case 'nouveau':
              this.uuid_actuel = uuidv4()
              const copieTableauxBlancs = _.clone(this.TableauxBlancs)
              copieTableauxBlancs[this.uuid_actuel] = {
                uuid: this.uuid_actuel,
                paramètres: {
                  outil: 'stylo',
                  couleur: '#212121',
                  taille: 1,
                  surligner: false
                },
                historique: {
                  undoStack: [],
                  redoStack: [],
                  currentSnapshot: null
                },
                image: event?.image || '',
                largeur: event?.largeur || 800,
                hauteur: event?.hauteur || 600
              }
              if (!this.uuid_actuel) return
              
              this.updateTableauxBlancs(copieTableauxBlancs)
              

          }
          // Comme on a changé les outils
          this.majOutils()
          break
        case 'aide':
          this.$buefy.dialog.alert({
            title: 'Problème de pointeur décalé ? (Epson)',
            message: "<strong>1.</strong> Passez la vidéo en mode souris.<br /><strong>2.</strong> Appuyez sur la touche [menu] de votre télécommande. <br /><strong>3.</strong> Naviguez jusqu'à : <strong>Avancé / Easy interactive Function / Interactivité PC / Avancé / Réglage man. zone crayon</strong>. <br /><strong>4.</strong> Suivez les instructions à l'écran pour recalibrer la zone du crayon.</p>",
            confirmText: 'ok',
            type: 'is-info',
            hasIcon: true
          })
          break
        
    }
    },
    enregistre() {
      if (!this.uuid_actuel)
        return
      const data = this.$refs.whiteboard.save(this.uuid_actuel)
      let copieTableauxBlancs = _.clone(this.TableauxBlancs)
      copieTableauxBlancs[this.uuid_actuel] = data
      this.updateTableauxBlancs(copieTableauxBlancs)
    },
    majOutils() {
      this.outil = this.TableauxBlancs?.[this.uuid_actuel]?.paramètres?.outil ?? 'stylo'
      this.couleur = this.TableauxBlancs?.[this.uuid_actuel]?.paramètres?.couleur ?? '#212121'
      this.taille = this.TableauxBlancs?.[this.uuid_actuel]?.paramètres?.taille ?? 1
      this.surligner = this.TableauxBlancs?.[this.uuid_actuel]?.paramètres?.surligner ?? false
      this.$refs.whiteboard.$el.style.cursor = this.createCursor(this.taille_outil_px, this.couleur, this.surligner)
    },
    supprimerTableau(uuid_a_supprimer) {
      let liste_uuids = Object.keys(this.TableauxBlancs)
      let ancien_index = _.findIndex(liste_uuids, uuid => uuid == uuid_a_supprimer)
      
      let copieTableauxBlancs = _.clone(this.TableauxBlancs)
      delete copieTableauxBlancs[uuid_a_supprimer]
      liste_uuids = _.pull(liste_uuids, uuid_a_supprimer)
      this.updateTableauxBlancs(copieTableauxBlancs)
      
      this.uuid_actuel = liste_uuids?.[ancien_index] ?? liste_uuids?.[ancien_index - 1] ?? undefined
      if (!this.uuid_actuel)
        this.ActionMenu({name: 'tableau', value: 'nouveau' })
    },
    handleDrop(event) {
      NouveauFichierDragdrop(event, this.$buefy, this.ActionMenu, this.$emit)
      this.$refs.whiteboard.$el.style.cursor = this.createCursor(this.taille_outil_px, this.couleur, this.surligner)
    },
    handleKeyDown(event) {
      // Page suivante avec flèche droite ou PageDown
      if (event.key === 'ArrowRight' || event.key === 'PageDown') {
        if (!this.dernierTableau) {
          this.ActionMenu({name: 'tableau', value: 'suivant'})
        }
        else if (this.tableauModifié)
        {
          this.ActionMenu({name: 'tableau', value: 'nouveau'})
        }
      }
      // Page précédente avec flèche gauche ou PageUp
      else if (event.key === 'ArrowLeft' || event.key === 'PageUp') {
        if (!this.premierTableau) {
          this.ActionMenu({name: 'tableau', value: 'précédent'})
        }
      }
    },
  }
}
</script>

<style scoped>
.tbi-container {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.tableau {
  position: fixed;
  top: 0;
  left: 90px;
  right: 0px;
  bottom: 0px;

}

.whiteboard {
  width: 100%;
  height: 100%;
}
</style>
