import { curveBasis, line } from 'd3'
import { getTransformedPoint } from './coordinates'
import { AjouterHistorique } from './historique'

const d3Line = line().curve(curveBasis)

export const setLineAttributes = (props) => {
  const styles =
    {
      fill: 'none',
      stroke: props.color,
      'stroke-width': props.size+'px',
      'stroke-linejoin': 'miter',
      'stroke-linecap': 'round',
      'stroke-opacity': props.surligner?0.5:1
    }
  
  return Object.entries(styles)
    .map(([key, value]) => `${key}: ${value};`)
    .join(' ')
}

export const stylo = (event, state, props) => {
  let isDrawing = true;

  state.activeLine = state.svg
    .select('g')
    .append('path')
    .datum([getTransformedPoint(event, state.container)])
    .attr('class', 'line')
    .attr('style', setLineAttributes(props))
  state.activeLine.attr('d', d3Line)

  const stopDrawing = () => {
    isDrawing = false;
  }

  const startDrawing = () => {
    isDrawing = true;
  }

  // Ajouter les gestionnaires d'événements
  state.container.addEventListener('mouseleave', stopDrawing);
  state.container.addEventListener('mouseenter', startDrawing);

  event.on('drag', (event) => {
    if (!isDrawing) return;
    
    const coordinates = getTransformedPoint(event, state.container);
    state.activeLine.datum().push(coordinates);
    state.activeLine.attr('d', d3Line);
  })

  event.on('end', (event) => {
    state.container.removeEventListener('mouseleave', stopDrawing);
    state.container.removeEventListener('mouseenter', startDrawing);
    AjouterHistorique(state);
  })
}
