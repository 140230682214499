import { curveBasis, line, select } from 'd3'
import { v4 as uuidv4 } from 'uuid'
import { getTransformedPoint } from './coordinates'
import { AjouterHistorique } from './historique'

const d3Line = line().curve(curveBasis)

export const gomme = (event, state, props) => {
  let isErasing = true;
  const startPoint = getTransformedPoint(event, state.container)
  
  const mainGroup = state.svg.select('g')

  const maskId = `lineMask-${uuidv4()}`
  const svgElement = state.container
  const defs = svgElement.querySelector('defs')
  
  // Récupérer les dimensions du SVG
  const width = svgElement.clientWidth
  const height = svgElement.clientHeight
  
  // Créer le masque avec une zone plus large
  const mask = select(defs)
    .append('mask')
    .attr('id', maskId)
  
  // Ajouter le rectangle blanc de fond avec une marge supplémentaire
  const margin = Math.max(width, height) // Marge égale à la plus grande dimension
  mask.append('rect')
    .attr('x', -margin)
    .attr('y', -margin)
    .attr('width', width + 2 * margin)
    .attr('height', height + 2 * margin)
    .attr('fill', 'white')

  // Créer le nouveau groupe avec le masque dans le groupe principal
  const newGroup = mainGroup
    .append('g')
    .attr('mask', `url(#${maskId})`)

  // Collecter tous les éléments à déplacer (uniquement ceux dans le groupe principal)
  const elementsToMove = []
  mainGroup.node().childNodes.forEach(child => {
    if (
      child.tagName !== 'defs' && 
      child.tagName !== 'image' && 
      child !== newGroup.node()
    ) {
      elementsToMove.push(child)
    }
  })

  // Déplacer les éléments dans le nouveau groupe
  elementsToMove.forEach(element => {
    newGroup.node().appendChild(element)
  })

  // Créer le tracé de la gomme
  const activeLine = mask
    .append('path')
    .datum([startPoint]) // Commencer avec les coordonnées transformées
    .attr('class', 'eraser-line')
    .attr('fill', 'none')
    .attr('stroke', 'black')
    .attr('stroke-width', props.size+'px')
    .attr('stroke-linejoin', 'miter')
    .attr('stroke-linecap', 'round')
  
  activeLine.attr('d', d3Line)

  const stopErasing = () => {
    isErasing = false;
  }

  const startErasing = () => {
    isErasing = true;
  }

  state.container.addEventListener('mouseleave', stopErasing);
  state.container.addEventListener('mouseenter', startErasing);

  event.on('drag', (event) => {
    if (!isErasing) return;

    const coordinates = getTransformedPoint(event, state.container)
    const currentPoints = activeLine.datum()
    activeLine.datum([...currentPoints, coordinates])
    activeLine.attr('d', d3Line)

  })

  event.on('end', (event) => {
    state.container.removeEventListener('mouseleave', stopErasing);
    state.container.removeEventListener('mouseenter', startErasing);
    AjouterHistorique(state)
  })
}
