import { setLineAttributes } from './stylo'
import { getTransformedPoint } from './coordinates'
import { AjouterHistorique } from './historique'

export const constrainAngle = (x1, y1, x2, y2, constrain) => {
  if (!constrain) return { x: x2, y: y2 }
  
  const dx = x2 - x1
  const dy = y2 - y1
  const angle = Math.atan2(dy, dx) * 180 / Math.PI
  
  // Arrondir à l'angle le plus proche de 45°
  const snap = Math.round(angle / 45) * 45
  const radians = snap * Math.PI / 180
  
  // Calculer la nouvelle position en conservant la distance
  const distance = Math.sqrt(dx * dx + dy * dy)
  return {
    x: x1 + Math.cos(radians) * distance,
    y: y1 + Math.sin(radians) * distance
  }
}

export const ligne = (event, state, props) => {
  let isDrawing = true;
  const startPoint = getTransformedPoint(event, state.container)

  state.activeLine = state.svg
    .select('g')
    .append('line')
    .attr('x1', startPoint[0])
    .attr('y1', startPoint[1])
    .attr('x2', startPoint[0])
    .attr('y2', startPoint[1])
    .attr('style', setLineAttributes({ ...props, 'stroke-dasharray': '5,5' }))

  const stopDrawing = () => {
    isDrawing = false;
  }

  const startDrawing = () => {
    isDrawing = true;
  }

  state.container.addEventListener('mouseleave', stopDrawing);
  state.container.addEventListener('mouseenter', startDrawing);

  event.on('drag', (event) => {
    if (!isDrawing) return;

    const coordinates = getTransformedPoint(event, state.container)
    const constrained = constrainAngle(
      startPoint[0],
      startPoint[1],
      coordinates[0],
      coordinates[1],
      event.sourceEvent.shiftKey
    )

    state.activeLine
      .attr('x2', constrained.x)
      .attr('y2', constrained.y)
  })

  event.on('end', (event) => {
    state.container.removeEventListener('mouseleave', stopDrawing);
    state.container.removeEventListener('mouseenter', startDrawing);

    const coordinates = getTransformedPoint(event, state.container)
    const constrained = constrainAngle(
      startPoint[0],
      startPoint[1],
      coordinates[0],
      coordinates[1],
      event.sourceEvent.shiftKey
    )

    state.activeLine
      .attr('x2', constrained.x)
      .attr('y2', constrained.y)
      .attr('style', setLineAttributes(props))

    AjouterHistorique(state)
  })
}
