import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf'

// Configuration du worker et des polices standard pour PDF.js
GlobalWorkerOptions.workerSrc = '/forge-de-cours/pdf.worker.js'
const STANDARD_FONT_DATA_URL = '/forge-de-cours/standard_fonts/'

function LitFichier(reader, type, blob, $buefy, ActionMenu, emit) {
  emit('chargement', {icone: 'file-image', tourne: false, texte: 'Importation en cours', annulable: true})
  reader.onload = (e) => {
    const contenu = e.target.result
    switch(type) {
      case 'image':
        NouvelleImage(contenu, ActionMenu)
        emit('chargement', false)
        break
      case 'pdf':
        pdf2jpgs(contenu).then(jpgs => {
          $buefy.toast.open({
            message: `PDF importé avec succès : (${jpgs.length} pages)`,
            type: 'is-success',
            position: 'is-top',
            duration: 5000,
            queue: false
          })
          for (const jpg of jpgs) {
            NouvelleImage(jpg, ActionMenu)
          }
          emit('chargement', false)
        })
        break
      default:
        console.error('Type de fichier non supporté:', type)
        emit('chargement', false)
    }
  }
  reader.readAsDataURL(blob)
}

function NouvelleImage(contenu, ActionMenu) {
  const img = new Image()
  // Attendre que l'image soit chargée pour obtenir ses dimensions
  img.onload = () => {
    ActionMenu({name:'nouvelleImage', image: {src: contenu, largeur: img.width, hauteur: img.height}})
  }
  img.src = contenu
}

export function CopierColler(event, $buefy, ActionMenu, emit) {
  const items = event.clipboardData.items

  for (const item of items) {
    if (item.type.includes('image')) {
      const blob = item.getAsFile()
      const reader = new FileReader()
      LitFichier(reader, 'image', blob, $buefy, ActionMenu, emit)
      return
    }
  }
}

export function NouveauFichierDragdrop(event, $buefy, ActionMenu, emit) {
  const files = event.dataTransfer.files
  if (files.length > 0) {
    const file = files[0]
    const reader = new FileReader()
    
    if (file.type.match('image.*')) {
      LitFichier(reader, 'image', file, $buefy, ActionMenu, emit)
    } else if (file.type === 'application/pdf') {
      LitFichier(reader, 'pdf', file, $buefy, ActionMenu, emit)
    }
  }
}

async function pdf2jpgs(base64Data) {
  // 1. Décoder le base64
  const binaryData = atob(base64Data.split(',')[1]);
  const uint8Array = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  // 2. Charger le PDF avec pdf.js
  const chargementTask = getDocument({
    data: uint8Array,
    standardFontDataUrl: STANDARD_FONT_DATA_URL
  });
  const pdf = await chargementTask.promise;
  
  // 3. Créer un tableau de promesses pour chaque page
  const promises = [];
  for (let i = 1; i <= pdf.numPages; i++) {
    promises.push((async () => {
      const page = await pdf.getPage(i);
      const scale = 250 / 72;
      const viewport = page.getViewport({ scale });
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      await page.render({ 
        canvasContext: context, 
        viewport: viewport,
        background: 'rgb(255, 255, 255)'
      }).promise;

      const jpgData = canvas.toDataURL('image/jpeg', 0.8);
      const tailleMo = (jpgData.length * 0.75) / (1024 * 1024);
      return jpgData;
    })());
  }

  // 4. Attendre que toutes les pages soient converties
  return Promise.all(promises);
}

