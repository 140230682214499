<template>
  <svg
    ref="container"
    @keydown="handleKeyDown"
    @wheel.prevent="onWheel"
    @contextmenu.prevent
    tabindex="0"
  >
  </svg>
</template>

<script>
import { onMounted, reactive, toRefs, computed, watch } from 'vue'
import { useStore } from 'vuex';
import { drag, image, select } from 'd3'
import { stylo } from './utils/stylo'
import { gomme } from './utils/gomme'
import { ligne } from './utils/ligne'
import { fleche } from './utils/fleche'
import { texte, EditeTexte, SauveTexte, getScale, adjustTextareaSize } from './utils/texte'
import { déplacer, déplacegroupe } from './utils/déplacement'
import { undoAction, redoAction, restoreSnapshot } from './utils/historique'
import { saveSvg } from './utils/save'
import { zoom, wheelZoom, TailleOutilZoom } from './utils/zoom'



export default {
  name: 'VueWhiteboard',
  //emits: ['demandeMajOutlis'],
  props: {
    color: {
      type: String,
      default: '#333333',
    },
    size: {
      type: Number,
      default: 2,
    },
    outil: {
      type: String,
      default: 'stylo'
    },
    surligner: {
      type: Boolean,
      default: false
    },
    tableauActuel: {
      type: Object,
      default: () => null
    },
    taille_index: {
      type: Number,
      default: 1
    }
  },
  setup(props, { emit }) {

    const store = useStore();

    const state = reactive({
      container: null,
      svg: null,
      activeLine: null,
      undoStack: [],
      redoStack: [],
      currentSnapshot: null,
      tempText: '',
      activeTextElement: null,
      currentTextHandler: null,
    })

    const remplacements = computed(() => store.state.remplacements);
    const tbi_image_a_editer = computed(() => store.state.tbi_image_a_editer);

    const getScaledProps = () => {
      return {
        ...props,
        size: TailleOutilZoom(props.size, state),
      }
    }

    const onWheel = (e) => {
      wheelZoom(e, state)
    }

    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && state.activeTextElement) {
        e.preventDefault();
        SauveTexte(state);
      }
    }

    const init = () => {
      state.container = state.container || select(state.container).node();
      state.svg = select(state.container)
      restoreSnapshot(state, null, tbi_image_a_editer.value)
      if (tbi_image_a_editer.value) {
        store.commit('updateTbiImageAEditer', null)
      }
        state.svg.call(
          drag()
            .container(state.container)
            .filter((event) => {
              // Vérifier si on clique sur un groupe de texte avec l'événement natif
              const clickedElement = event?.sourceEvent?.target ?? event?.target;
              if (!clickedElement) return true;
              const textInput = clickedElement.closest('.fdc-tbi-text-input');
              if (textInput) {
                return false;
              }
              return true;
            })
            .on('start', (event) => {
              // Si on a un texte en cours d'édition, on le termine
              if (state.activeTextElement) {
                SauveTexte(state);
              }

              // Vérifier si on clique sur un groupe de texte
              const clickedElement = event.sourceEvent.target;
              if (!clickedElement) return;
              const textGroup = clickedElement.closest('.fdc-tbi-text-group');
              if (textGroup && props.outil == 'texte') {
                if (event.sourceEvent.button === 2) {
                  déplacegroupe(event, textGroup, state);
                } else {
                  EditeTexte(textGroup, state);
                }
                return;
              }

              // Clic droit (button === 2)
              if (event.sourceEvent.button === 2) {
                déplacer(event, state)
                return;
              }

              // Clic gauche (button === 0)
              switch(props.outil) {
                case 'stylo':
                  stylo(event, state, getScaledProps())
                  break
                case 'gomme':
                  gomme(event, state, getScaledProps())
                  break
                case 'ligne':
                  ligne(event, state, getScaledProps())
                  break
                case 'fleche':
                  fleche(event, state, getScaledProps())
                  break
                case 'texte':
                  texte(event, state, getScaledProps(), remplacements.value)
                  break
                case 'déplacer':
                  déplacer(event, state)
                  break
                case 'zoom+':
                  zoom(event, state, 1.5)
                  break
                case 'zoom-':
                  zoom(event, state, 0.67)
                  break
              }
            })
        )
    }

    // Ajouter des watchers pour les props
    watch(() => props.color, (newColor) => {
      if (state.activeTextElement) {
        const textarea = state.activeTextElement.select('textarea');
        textarea.style('color', newColor);
      }
    });

    watch(() => props.size, (newSize) => {
      if (state.activeTextElement) {
        const scale = getScale(state.container);
        const textarea = state.activeTextElement.select('textarea');
        textarea.style('font-size', `${newSize / scale}px`)
              .style('height', `${(newSize * 1.2) / scale}px`);
        adjustTextareaSize(textarea.node());
      }
    });
    watch(() => props.tableauActuel, (tableau) => {
        state.undoStack = tableau?.historique?.undoStack ?? [];
        state.redoStack = tableau?.historique?.redoStack ?? [];
        state.currentSnapshot = tableau?.historique?.currentSnapshot ?? null;

        restoreSnapshot(state, state.currentSnapshot, tableau?.image ?? {})
    });
    const undo = () => {
      undoAction(state)
    }

    const redo = () => {
      redoAction(state)
    }

    const save = () => {
      return saveSvg(state, props)
    }

    onMounted(init)

    return {
      ...toRefs(state),
      handleKeyDown,
      remplacements,
      onWheel,
      undo,
      redo,
      save,
    }
  },
}
</script>

<style scoped>
svg {
  outline: none; /* Pour éviter la bordure de focus due au tabindex */
}

:deep(.text-input) {
  border: 1px solid black;
  background: #ffffff99;
  font-family: Arial;
  padding: 2px;
  box-sizing: border-box;
  resize: none;
  overflow: hidden;
  word-wrap: break-word;
  line-height: 1.2;
}
</style>