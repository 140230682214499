import { createStore } from 'vuex'
import FdcConfig from '@/js/FdcConfig';
import ConfigLectureSeule from '../assets/ConfigLectureSeule.json'
import configMinimale from '../assets/configMinimale.json'
import { toRaw } from 'vue';
import Dropbox from '@/js/Dropbox';
import tri_classes from '@/js/tri_classes';
import { update } from 'lodash';

export default createStore({
  state: {
    // Objet ma config qui gère toute la configuration
    maconfig: null,
    // configuration pour pousser une nouvelle configuration
    configuration: {},
    // contenu de la configuration accessible de façon réactive
    liens: [],
    établissements: {},
    classes : {},
    classes_ordonnées: [],
    commandes: [],
    remplacements: null,
    caractères: [],
    auteur: "",
    police: "",
    horloge: "",
    éditeur_équations: true,
    éditeur_circuits: true,
    tbi: true,
    editerCommeNouveau: null,
    NouvelleVisite: true,

    typeConfiguration: "",
    typehorloge: "",
    éditeurs: [],
    indexÉditeurEnCours: -1,
    commandeediteur: null,
    TirageTitreAnimés: null,
    last_erreur: false,
    TableauxBlancs: {},
    TableauxBlancsASupprimer: [],
    tbi_image_a_editer: null,

    photos: [],
    chargementPhotos: false,

    autoriseNavigation: false,

    synchro_en_cours: false,
    modif_liste_cours: false
  },
  getters: {
  },
  mutations: {
    updateNouvelleVisite(state) {
      this.state.NouvelleVisite = false;
    },

    updateTableauxBlancs(state, newValue) {
      state.TableauxBlancs = newValue;
    },
    updateTableauxBlancsASupprimer(state, newValue) {
      state.TableauxBlancsASupprimer = newValue;
    },
    updateTbiImageAEditer(state, newValue) {
      state.tbi_image_a_editer = newValue;
    },
    updateTypehorloge(state, newValue) {
      state.typehorloge = newValue;
    },
    // newValue : index de l'éditeur OU '' si c'est un nouveau
    updateindexÉditeurEnCours(state, newValue) {
      if (newValue !== '')
        state.indexÉditeurEnCours = newValue;
      else
        state.indexÉditeurEnCours = state.éditeurs.length - 1
    },
    updateéditeurs(state, newValue) {
      if (newValue.éditeur === null)
      {
        state.éditeurs.splice(newValue.id, 1)
      }
      else if (newValue.id === '')
        state.éditeurs.push(newValue.éditeur)
      else
        state.éditeurs[newValue.id] = newValue.éditeur;
    },
    updateCommandeEditeur(state, newValue) {
      state.commandeediteur = newValue;
    },
    updateTirageTitreAnimés(state, newValue) {
      state.TirageTitreAnimés = newValue;
    },
    updateMaConfig(state, newValue) {
      state.maconfig = newValue;
    },
    updateErreur(state, newValue) {
      state.erreur = newValue;
    },
    updateEditerCommeNouveau(state, newValue) {
      state.editerCommeNouveau = newValue;
    },
    razConfig(state) {
      state.maconfig = null
    },
    updateAutoriseNavigation(state, newValue) {
      state.autoriseNavigation = newValue;
    },
    updateModifListeCours(state) {
      state.modif_liste_cours = new Date().getTime;
    }
    
  },
  actions: {
    /*
    * parametres:
    * {
    *  configuration: null | {}
    *  sens: "EcraseLeStore" pour écraser le store, "Sync" pour les deux sens, "EcrasePartagé" pour écraser config enregistrée dossier/dropbox/navigateur
    */
    async syncConfiguration({state}, parametres) {
      // On attend s'il le faut
      while (state.synchro_en_cours)
        {
          await new Promise(resolve => setTimeout(resolve, 100))
        }
      // On synchronise
      return new Promise( (resolve, reject) => {
        
        state.synchro_en_cours = true
        // 1ere synchro de type écrase le store
          if (state.maconfig == null)
          {
            state.maconfig = new FdcConfig(parametres.$buefy);
            state.typeConfiguration = state.maconfig.getTypeStockage();
            state.maconfig.sync(null, "EcraseLeStore")
            .then( config => resolve(config) ).catch(err => reject(err))
          }
          // ou synchro normale
          else
          {
            state.maconfig.sync(parametres.configuration == null ? state.configuration:parametres.configuration, parametres.sens)
            .then( config => resolve(config) ).catch(err => reject(err))
          }
        })
      
      // On copie tous les objets
      .then( (nouvelleConfiguration) => {
        if (!nouvelleConfiguration)
          {
            state.synchro_en_cours = false
            return Promise.reject("La configuration est vide !")
          }
        state.typeConfiguration = state.maconfig.getTypeStockage();
        state.configuration = {...configMinimale, ..._.cloneDeep(nouvelleConfiguration)}
        state.liens = _.cloneDeep(state.configuration?.liens || [])
        state.établissements = _.cloneDeep(state.configuration?.établissements || {})
        state.classes = _.cloneDeep(state.configuration?.classes || {})
        state.classes_ordonnées = tri_classes.tri(state.configuration)
        state.commandes = _.concat(ConfigLectureSeule.commandes, state.configuration?.editeur?.commandes.map(e => toRaw(e)))
        state.caractères = _.cloneDeep(state.configuration?.editeur?.caractères || [])
        state.auteur = _.cloneDeep(state.configuration?.editeur?.auteur || "")
        state.police = _.cloneDeep(state.configuration?.editeur?.police || "")
        state.horloge = state.configuration?.editeur?.horloge ?? false
        state.éditeur_équations = state.configuration?.editeur?.éditeur_équations ?? false
        state.éditeur_circuits = state.configuration?.editeur?.éditeur_circuits ?? true
        state.tbi = state.configuration?.editeur?.tbi ?? true
        // localStorage.remplacements = JSON.stringify(state.configuration?.editeur?.remplacements || [])
        // Doit être la dernière ligne car lorsque remplacements ne sera plus null, tout le reste de la configuration sera chargé (test dans cours/edition.vue)
        state.remplacements = _.cloneDeep(state.configuration?.editeur?.remplacements || [])
        state.synchro_en_cours = false
        if (parametres.message_ok && state.typeConfiguration.type !== 'temporaire')
        {
          parametres.$buefy.snackbar.open({
            duration: 5000,
            message: `${parametres.message_ok}`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'ok',
            queue: false
         })
        }
        state.last_erreur = false
      })
      // Erreurs
      .catch( (erreur) => {
        state.synchro_en_cours = false
        if (!state.last_erreur)
        {
          parametres.$buefy.snackbar.open({
                duration: 10000,
                message: `Une erreur est survenue lors de la synchronisation de la configuration : ${erreur}`,
                type: 'is-danger',
                position: 'is-top',
                actionText: 'ok',
                queue: false
            })
          }
        state.last_erreur = true
      })
    },
    RécupèreDernièresPhotos({state}, index) {
      state.photos=[]
      state.chargementPhotos = true
      let dbx = new Dropbox()
      return dbx.getZip("/photos",'jpg', 5)
      .then( photos => {
        state.photos = photos
        state.chargementPhotos = false
        if (index === undefined)
          return Promise.resolve(state.photos)
        else if (index-1 < photos.length)
          return Promise.resolve(state.photos[index-1])
        else
          return Promise.reject("Cette photo n'existe pas encore !")
        }
      )




        // state.chargementPhotos = true
        // let dbx = new Dropbox()
        // let listeNomsTéléchargés = []
        // return dbx.getFolder("/photos")
        // .then ( photos => {
        //     let listeNoms = photos.map( p => p.nom)
        //     let Promesses = []
        //     for (let photo of photos)
        //     {
        //       if (!listeNoms.includes(photo.name))
        //       {
        //         listeNomsTéléchargés.push(photo.name)
        //         Promesses.push(dbx.getFileBinary(photo.path_lower))
        //       }
        //     }
        //     return Promise.all(Promesses)
        // })
        // .then( photos => {
        //     for (let index in photos.reverse().slice(0,5))
        //     {
        //       state.photos[index]={nom: listeNomsTéléchargés[index], data:`data:image/jpeg;base64,${btoa(String.fromCharCode.apply(null, new Uint8Array(photos[index])))}`}
        //       state.photos = state.photos
        //     }
        //     if (photos.length < 5)
        //       return Promise.resolve([])
        //     return Promise.resolve(listeNomsTéléchargés.slice(0,photos.length-5))
        // })
        // .then( (listeàsupprimer) => {
        //   if (listeàsupprimer.length == 0)
        //     return Promise.resolve()
        //   return dbx.deleteFiles(listeàsupprimer.map(nom => {return { path: "/photos/" + nom}}))
        // })
        // .finally( () => state.chargementPhotos = false)
    },
  },
  modules: {
  }
})
