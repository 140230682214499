const MAX_UNDO = 10

const takeSnapshot = (svg) => {
  return svg.node().cloneNode(true)
}

export const restoreSnapshot = (state, snapshot = null, image = null) => {
  if (snapshot) {
    // Copier tous les attributs du SVG du snapshot
    const attributes = snapshot.attributes;
    for (let i = 0; i < attributes.length; i++) {
      const attr = attributes[i];
      state.svg.node().setAttribute(attr.name, attr.value);
    }
    // Puis remplacer le contenu
    state.svg.node().innerHTML = snapshot.innerHTML;
  } else {
    state.svg.selectAll('*').remove();
    const g = state.svg.append('g')
    g.append('defs').attr('id', 'TEST')
    if (image)
      {
        g.append('image')
          .attr('href', image?.src ?? '')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', image?.largeur ?? 800)
          .attr('height', image?.hauteur ?? 600)
          .attr('preserveAspectRatio', 'xMidYMid meet')
      }
  }
}

export const AjouterHistorique = (state) => {
  state.undoStack.push(state.currentSnapshot)
  if (state.undoStack.length > MAX_UNDO)
    state.undoStack.shift()
  state.currentSnapshot = takeSnapshot(state.svg)
  state.redoStack = []
}

export const undoAction = (state) => {
  if (!state.undoStack.length) return
  
  state.redoStack.push(state.currentSnapshot)
  state.currentSnapshot = state.undoStack.pop()
  if (state.currentSnapshot)
    restoreSnapshot(state, state.currentSnapshot)
  else
    restoreSnapshot(state)
}

export const redoAction = (state) => {
  if (!state.redoStack.length) return
  if (state.undoStack.length > MAX_UNDO)
    state.undoStack.shift()
  state.undoStack.push(state.currentSnapshot)
  state.currentSnapshot = state.redoStack.pop()
  restoreSnapshot(state, state.currentSnapshot)
}
