<template>
  <div class="bureau">
    <b-loading is-full-page v-model="loading" :can-cancel="loading?.annulable === undefined || loading?.annulable">
     
      <div class="is-flex is-flex-direction-column is-align-items-center loading-info grostexte" v-if="loading.icone || loading.texte">
        <b-icon
          v-if="loading.icone"
          pack='fas'
          type="is-primary"
          :icon="loading.icone"
          :custom-class="{'fa-spin':loading?.tourne}" />
        <span v-if="loading.texte" class="mt-5 has-text-primary is-size-1">{{ loading?.texte}}</span>
      </div>
      <div class="is-flex is-flex-direction-column is-align-items-center loading-info grostexte" v-else>
        <b-icon
          pack="fas"
          type="is-primary"
          icon="spinner"
          custom-class="fa-spin" />
        <span class="mt-5 has-text-primary is-size-1">Préparation de la page…</span>
      </div>
    </b-loading>

      <Menu  ref="menu_ref"  @montre_moi_un_cours="montre_moi_un_cours" @montre_moi_une_image="montre_moi_une_image" @chargement="chargement" @montreTBI="modal_TBI=true"/>
      <div id="mon-container" @click="cacherlemenu">
        <Hero :hero="hero" @aide="aide"/>
        <div class="p-4" id="page" v-if="config_lue">
          <router-view v-slot="{ Component }">
            <keep-alive>
              <component  :is="Component"
                          @changerhero="changerhero"
                          @montre_moi_un_cours="montre_moi_un_cours"
                          @montre_moi_une_image="montre_moi_une_image"
                          @chargement="chargement"
                          @montreTBI="montreleTBI"
                          />
            </keep-alive>

            <Horloge />
          </router-view>
        </div>
      </div>

    <TBI v-show="modal_TBI" @close="modal_TBI=false" @chargement="chargement" ref="ref_TBI"/>
    <MontreMoiUneImage :photos="photos" :index="photo_index" @close="modal_image = false" :visible="modal_image" />
    <MontreMoiUnCours :cours="cours" :typeliste="typeliste" @cours_affiché="cours_affiché" @montre_moi_un_cours="montre_moi_un_cours" @montre_moi_une_image="montre_moi_une_image"/>
    <v-tour name="visiteguidée" :steps="steps" :options="optionsvisiteguidée"></v-tour>
    <div id="teleport_modaux"></div>
  </div>
</template>
<script>
  import { mapState, mapMutations, mapActions } from 'vuex';
  import Menu from '@/components/Menu/Menu.vue'
  import Hero from '@/components/Hero.vue';
  import Horloge from '@/components/Horloge.vue'
  import MontreMoiUnCours from '@/components/Modal/MontreMoiUnCours.vue';
  import MontreMoiUneImage from '@/components/Modal/MontreMoiUneImage.vue';
  import TBI from '@/components/TBI/tbi.vue'
  
  export default {
    emits: ['changerhero', 'montre_moi_un_cours', 'montre_moi_une_image', 'chargement','pack','tbi'],
    name: 'App',
    data() {
      return {
        hero: {titre:"",soustitre:"", page:"", sanstitre: false},
        cours: null,
        typeliste: null,
        //image: null,
        photos: {
          value: []
        },
        photo_index: 0,
        modal_image: false,
        modal_TBI: false,
        loading: false,
        config_lue: false,
        steps: [
          {
            target: '#v-step-0',  // We're using document.querySelector() under the hood
            header: {
              title: 'Get Started',
            },
            content: `Discover <strong>Vue Tour</strong>!`
          },
        ],
        optionsvisiteguidée: {
          useKeyboardNavigation: true,
          highlight: true,
          labels: {
            buttonSkip: 'Passer',
            buttonPrevious: 'Précédent',
            buttonNext: 'Suivant',
            buttonStop: 'Terminé'
          }
        }
      };
    },
    watch: {
      modal_TBI(val) {
        if (val)
          {
            // on masque div.ck-body-wrapper
            if (document.querySelector('div.ck-body-wrapper'))
              document.querySelector('div.ck-body-wrapper').style.display = 'none'
          }
        else
          {
            if (document.querySelector('div.ck-body-wrapper'))
              document.querySelector('div.ck-body-wrapper').style.display = 'unset'
          }
      }
    },
    mounted() {
    // On récupère la config le plus tôt possible, entre autre pour le menu
    this.chargement( {icone: 'book-open-reader', tourne: false, texte: 'Lecture base de données', annulable: true})
    this.syncConfiguration({configuration: null,  sens:"Sync", $buefy: this.$buefy})
    .finally( () => 
    {
      this.config_lue = true
      this.chargement(false)
      this.cours =  null;
      if (this.horloge)
          this.updateTypehorloge({type:'Horloge',taille:'miniature'})
      else
        this.updateTypehorloge('')
      window.addEventListener('beforeunload', this.handlePageUnload);
      window.addEventListener('unload', this.handlePageUnload);


    })
     
    },
    beforeUnmount() {
      window.removeEventListener('beforeunload', this.handlePageUnload);
      window.removeEventListener('unload', this.handlePageUnload);
    },
    components: {
      Hero,
      Menu,
      Horloge,
      MontreMoiUnCours,
      MontreMoiUneImage,
      TBI
    },
    computed: {
      ...mapState(['horloge','éditeurs','typeConfiguration','typehorloge'])
    },
    methods: {
      ...mapMutations(['updateTypehorloge']),
      ...mapActions(['syncConfiguration']),
      aide() {
        if (process.env.NODE_ENV === 'development'|| ! this.hero?.aide)
          return
        this.steps = []
        this.hero.aide.forEach(element => {
          if ( document.querySelector(element.target) )
            this.steps.push(element)
        });
        this.$tours['visiteguidée'].start()
      },
      changerhero(hero)
      {
        if (process.env.NODE_ENV === 'development')
          this.hero = {titre: hero?.titre, soustitre:hero.soustitre, page: hero?.page, aide:null}
        else
          this.hero = hero;
        document.title = hero.page != '' ? hero.page : "Forge de cours";
      },
      cacherlemenu() {
        this.$refs.menu_ref.cacherlemenu()
      },
      montre_moi_un_cours(data) {
        this.cours = data.cours
        this.typeliste = data.typeliste
      },
      cours_affiché() {
        this.cours = null
      },
      montre_moi_une_image(data) {
        this.photos = data.photos
        this.photo_index = data.index
        this.modal_image = true
      },
      handlePageUnload(e) {
        if (this.éditeurs.length)
        {
          let confirmationMessage = 'Votre travail n\'est pas enregistré.\nVoulez vous tout de même partir ?';
          e.preventDefault();
          e.returnValue = confirmationMessage
          return confirmationMessage
        }
      },
      chargement(isLoading)
      {
        this.loading = isLoading
      },
      montreleTBI(event) {
        // On a une image à afficher
        if (event.image)
          {
            const image = new Image();
            // On attend que l'image soit chargée pour faire le reste
            image.onload = () => {
              this.$refs.ref_TBI.ActionMenu({name: 'nouvelleImage', image: {src: event.image, largeur: image.width, hauteur: image.height}})
            }
            image.src = event.image;
          }
          this.modal_TBI = true
      }
    },
  };
  </script>
  
  <style>


  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  @font-face {
    font-family: "OpenDyslexic";
    src: url('../../fonts/OpenDyslexic-Regular.woff2') format('woff2'),
  }

  @font-face {
    font-family: "Gluten";
    src: url('../../fonts/Gluten-Bold.woff2') format('woff2'),
  }

  

  
  div#mon-container {
    /* largeur du menu*/
    margin-left: 40px;
    height: 100vh;
  }

  div.loading-background {
    backdrop-filter: blur(5px);
    background: rgba(0,0,0,.5);
  }

  .loading-info {
    z-index:1;
  }

  .grostexte {
    font-size: 100px;
  }

div#page {
  position: relative;
  z-index: 0;
}

  </style>