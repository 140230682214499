<template>
<div id="menu" @click="clic_menu">
    <ul data-v-step="menu" class="vertical-nav">
<!-- Plein écran -->
        <li data-v-step="menu_pleinecran" title="Plein écran" class="icone_menu sanssousmenu PleinEcran" @click="toggleFullScreen">
            <span class="icon has-text-primary" v-if="isFullScreen"><i class="fas fa-compress"></i></span>
            <span class="icon has-text-primary" v-else><i class="fas fa-expand"></i></span>
        </li>
<!-- Minuteurs -->
        <li class="icone_menu Minuteurs-et-chronometres" data-v-step="menu_horloge" title="Horloge, minuteurs…" v-if="éditeurs.length && (url === '/cours/edition')">
            <span class="icon has-text-primary"><i class="fas fa-clock"></i></span>
        </li>
<!-- Enregistrer / fermer -->
<li class="icone_menu sanssousmenu Enregistrer" data-v-step="menu_enregistrer" title="Enregistrer mon cours / bouton droit = fermer" v-if="éditeurs.length" @click="enregistrer(indexÉditeurEnCours)" @contextmenu.prevent="fermer(indexÉditeurEnCours)">
    <span class="icon has-text-primary"><i class="fas fa-save"></i></span>
</li>
<!-- Liste des éditeurs -->
        <li v-for="(éditeur, index) in éditeurs" :title="éditeur?.cours?.titre || '???'" class="icone_menu sanssousmenu Edition-du-cours" @click="change_editeur(index)">
            <b-tooltip  type="is-light" :triggers="['contextmenu']" :auto-close="['outside', 'escape']" position="is-right">
            <template v-slot:content>
                <div class="mb-2">{{éditeur?.cours?.titre}}</div>
                <div class="buttons is-flex is-justify-content-center">
                    <b-button icon-pack="fas" icon-left="save" type="is-primary"  @click="enregistrer(index)">Enregistrer</b-button>
                    <b-button icon-pack="fas" icon-left="close" type="is-primary" @click="fermer(index)">Fermer</b-button>
                </div>
            </template>
            <span class="icon has-text-primary" data-v-step="menu_éditeur" v-if="index == indexÉditeurEnCours"><i class="fas fa-file-signature"></i></span>
            <span class="icon has-text-primary" v-else><i class="fas fa-file-pen"></i></span>
        </b-tooltip>
        </li>
        <li data-v-step="menu_nouveaucours" class="icone_menu sanssousmenu Nouveau-cours" title="Faire un nouveau cours">
            <router-link to="/cours/choix-classe"><span class="icon has-text-primary"><i class="fas fa-file-circle-plus"></i></span></router-link>
        </li>
<!-- Tableau blanc -->
        <li class="icone_menu sanssousmenu Tableau-blanc" title="Tableau blanc interactif" @click="$emit('montreTBI', {})" v-if="tbi">
            <span class="icon has-text-primary"><i class="fas fa-chalkboard"></i></span>
        </li>
<!-- Photos -->
        <li class="icone_menu Mes-dernieres-photos"data-v-step="menu_photos"  title="Dernières photos" v-if="typeConfiguration.nuage" @click="RécupèreDernièresPhotos(undefined)">
            <span class="icon has-text-primary"><i class="fas fa-images"></i></span>
        </li>
<!-- Tirages -->
        <li class="icone_menu sanssousmenu Tirage-au-sort" data-v-step="menu_tirage" title="Tirer un élève au sort" v-if="showTirage() && (url === '/cours/edition')">
            <RouterLink :to="'/tirage/'"><span class="icon has-text-primary"><i class="fas fa-person-circle-question"></i></span></RouterLink>
        </li>
<!-- Liste des cours -->
        <li class="icone_menu Mes-derniers-cours" data-v-step="menu_derniers_cours" title="Mes derniers cours" @click="RecalculeDerniersCours()">
            <span class="icon has-text-primary"><i class="fas fa-backward-fast"></i></span>
        </li>
<!-- Planification -->
        <li class="icone_menu Planification-des-cours" data-v-step="menu_planification" title="Planification">
            <span class="icon has-text-primary"><i class="fas fa-calendar-check"></i></span>
        </li>
<!-- Liens -->
<li class="icone_menu Les-liens" title="Les liens" data-v-step="menu_liens" v-if="possede_lien_ent() || liens.length">
            <span class="icon has-text-primary"><i class="fas fa-link"></i></span>
        </li>
<!-- Configuration -->
        <li data-v-step="menu_configuration" class="icone_menu Configuration" title="Configuration">
            <span class="icon has-text-primary"><i class="fas fa-gear"></i></span>
        </li>
<!-- Forge de cours ?? -->
        <li class="icone_menu sanssousmenu ForgeDeCours" title="Forge de cours">
            <router-link to="/"><img  src="/forge-de-cours/img/icons/favicon-32x32.png" alt='F'/></router-link>
        </li>
    </ul>
    <aside class="menu">
        <ul class="menu-list Configuration" v-if="menu_affiché == 'Configuration'">
            <p class="menu-label px-2 pt-4">{{ typeConfiguration.message }}</p>
            <li>
                <router-link to="/configuration/ou-sauvegarder/actuel"><i class="fas fa-server"></i>Où et comment sauvegarder mes données ?</router-link>
            </li>
            <li>
                <router-link to="/configuration/etablissements"><i class="fas fa-school"></i>Mes établissements</router-link>
            </li>           
            <li>
                <router-link to="/configuration/classes"><i class="fas fa-users-between-lines"></i>Mes classes</router-link>
            </li>
            <li>
                <router-link to="/configuration/editeur"><i class="fas fa-file-pen"></i>Paramétrer l'éditeur</router-link>
            </li>
            <li>
                <router-link to="/configuration/liens"><i class="fas fa-link"></i> Ajouter ou retirer des liens</router-link>
            </li>
            <li>
                <router-link to="/configuration/ou-sauvegarder/importer-exporter"><i class="fas fa-arrow-right-arrow-left"></i> Importer / exporter la configuration</router-link>
            </li>
        </ul>
        <ul class="menu-list Mes-dernieres-photos" v-if="menu_affiché == 'Mes-dernieres-photos'">
            <p class="menu-label px-2 pt-4" v-if="photos">Mes dernières photos</p>
            <p class="menu-label px-2 pt-4 has-text-centered" v-if="chargementPhotos"><b-icon pack="fas" icon="spinner" size="is-small" custom-class="fa-spin"/></p> 
            <li v-for="(photo, index) in photos">
                <PhotoMenu :src="photo" @click="$emit('montre_moi_une_image', {photos:photos, index: index})"/>
            </li>
            <p class="p-3" v-if="! photos.length && !chargementPhotos">Il n'y a aucune photos.<br />Utilisez l'application mobile pour en prendre.</p>
            <p class="menu-label px-2 pt-4">QRcode pour l'application mobile</p>
            <li><div class="is-flex is-justify-content-center">
                <QRCodeVue3
                    :value="LienMobile"
                    :width="200"
                    :height="200"
                    :dotsOptions="{

                        type: 'dots',
                        color: '#7957d5',
                        gradient: {
                        type: 'linear',
                        rotation: 0,
                        colorStops: [
                            { offset: 0, color: '#26249a' },
                            { offset: 1, color: '#7957d5' },
                        ],
                        },
                    }"
                    />
            </div>
                
            </li>
        </ul>
        <ul class="menu-list Les-liens" v-if="configuration.liens && menu_affiché == 'Les-liens' && (possede_lien_ent() || possede_liens_personnels())">
            <p class="menu-label px-2 pt-4" v-if="possede_lien_ent()">Espaces numériques de travail</p>
            <li v-for="établissement in établissements" >
                <a v-if="établissement.lien" :href="établissement.lien" target="'_blank'"><i :class="établissement.icone"></i> {{établissement.nom}} <i class="fa-solid fa-arrow-up-right-from-square"></i></a>
            </li>
            <p class="menu-label px-2 pt-4" v-if="liens.length">Mes liens</p>
            <li v-for="lien in liens">
                <a :href="lien.lien" target="_blank"><i :class="lien.icone"></i> {{ lien.nom }} <i class="fa-solid fa-arrow-up-right-from-square"></i></a>
            </li>
        </ul>
        <ul class="menu-list Minuteurs-et-chronometres" v-if="menu_affiché == 'Minuteurs-et-chronometres'" @click="cacherlemenu()">
            <p class="menu-label px-2 pt-4">Placer dans la fenêtre</p>
            <li v-if="this.typehorloge.type !='Horloge'">
                <a @click="this.updateTypehorloge({type:'Horloge',taille:'miniature'});"><i class="fas fa-clock"></i> une horloge</a>
            </li>
            <li v-if="this.typehorloge.type !='Chronomètre'">
                <a @click="this.updateTypehorloge({type:'Chronomètre',taille:'grande'});"><i class="fas fa-stopwatch-20"></i> un chronomètre</a>
            </li>
            <li v-if="this.typehorloge.type !='Minuteur'">
                <a @click="this.updateTypehorloge({type:'Minuteur',taille:'grande'});"><i class="fas fa-stopwatch"></i> un minuteur</a>
            </li>
            <li v-if="this.typehorloge.type !=''">
                <a @click="this.updateTypehorloge({type:''});"><i class="fas fa-trash"></i> l'enlever</a>
            </li>
        </ul>
        <ul class="menu-list Planification-des-cours" v-if="menu_affiché == 'Planification-des-cours'">
            <p class="menu-label px-2 pt-4">Préparer mes cours, gérer mon temps</p>
            <li>
                <router-link to="/planification/cours"><i class="fas fa-calendar-check"></i> Planification</router-link>
            </li>
            <li>
                <router-link to="/planification/photocopies"><i class="fas fa-print"></i> Photocopies</router-link>
            </li>
            <li>
                <router-link to="/planification/matériel"><i class="fas fa-flask"></i> Matériel</router-link>
            </li>
            <li>
                <router-link to="/planification/tâches"><i class="fas fa-list"></i> Tâches</router-link>
            </li>
        </ul>
        <ul class="menu-list Mes-derniers-cours" v-if="menu_affiché == 'Mes-derniers-cours'">
            <p class="menu-label px-2 pt-4">Historique</p>
            <li>
                <router-link to="/cours/liste-des-cours"><i class="fas fa-list"></i> Afficher toutes les séances</router-link>
            </li>
            <p class="menu-label px-2 pt-4" v-if="cours_autosauve.length">Sauvegarde automatique</p>
            <li class="liste_cours px-3 py-1 is-flex is-align-items-center" v-for="uncours in cours_autosauve" @click="$emit('montre_moi_un_cours',{cours:uncours.doc, typeliste:'aucun'})">
                <b-taglist attached class="mr-1">
                    <b-tag type="is-primary" v-if="uncours.doc.classe">{{ uncours.doc.classe }}</b-tag>
                    <b-tag type="is-success" v-if="!uncours.doc.groupe && uncours.doc.classe" icon="crowd" >Classe entière</b-tag>
                    <b-tag type="is-warning" v-if="uncours.doc.groupe" icon="account-group">{{ uncours.doc.groupe }}</b-tag>

                    <b-tag type="is-primary" v-if="uncours.doc.type_document === 'préparation'" icon="timer-sand"/>
                    <b-tag type="is-black" v-if="uncours.doc.type_document === 'préparation'" >Préparation</b-tag>
                </b-taglist>
               {{ titre(uncours.doc) }}
            </li>
            <p class="menu-label px-2 pt-4" v-if="dernierscoursClasse.length">Derniers cours des {{ dernierscoursClasse[0].doc.classe}}</p>
            <p class="menu-label px-2 pt-4 has-text-centered" v-if="chargementCours && dernierscoursClasse.length"><b-icon pack="fas" icon="spinner" size="is-small" custom-class="fa-spin"/></p> 
            <li class="liste_cours px-3 py-1 is-flex is-align-items-center" v-for="uncours in dernierscoursClasse" @click="$emit('montre_moi_un_cours',{cours: uncours.doc, typeliste:'classe'})">
                <b-taglist attached class="mr-1">
                    <b-tag type="is-primary" v-if="uncours.doc.classe">{{ uncours.doc.classe }}</b-tag>
                    <b-tag type="is-success" v-if="!uncours.doc.groupe && uncours.doc.classe" icon="crowd" > Classe entière</b-tag>
                    <b-tag type="is-warning" v-if="uncours.doc.groupe" icon="account-group">{{ uncours.doc.groupe }}</b-tag>
                </b-taglist>
               {{ titre(uncours.doc) }}
            </li>
            <p class="menu-label px-2 pt-4">{{ dernierscoursClasse.length ? 'Les autres derniers cours':'Les derniers cours'}}</p>
            <p class="menu-label px-2 pt-4 has-text-centered" v-if="chargementCours"><b-icon pack="fas" icon="spinner" size="is-small" custom-class="fa-spin"/></p> 
            <p class="p-3" v-if="! dernierscours.length && !chargementCours">Aucun cours enregistré</p>
            <li class="liste_cours px-3 py-1 is-flex is-align-items-center" v-for="uncours in dernierscours.slice(0, 12 - dernierscoursClasse.length)" @click="$emit('montre_moi_un_cours',{cours:uncours.doc, typeliste:'tous' })">
                <b-taglist attached class="mr-1">
                    <b-tag type="is-primary" v-if="uncours.doc.classe">{{ uncours.doc.classe }}</b-tag>
                    <b-tag type="is-success" v-if="!uncours.doc.groupe && uncours.doc.classe" icon="crowd" > Classe entière</b-tag>
                    <b-tag type="is-warning" v-if="uncours.doc.groupe" icon="account-group">{{ uncours.doc.groupe }}</b-tag>
                    
                    <b-tag type="is-primary" v-if="uncours.doc.type_document === 'préparation'" icon="timer-sand"/>
                    <b-tag type="is-black" v-if="uncours.doc.type_document === 'préparation'" >Préparation</b-tag>
                </b-taglist>
               {{ titre(uncours.doc) }}
            </li>
           
        </ul>
    </aside>
</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import SauvegardeCours  from '@/js/SauvegardeCours';
import PouchDB from 'pouchdb-browser'
import PhotoMenu from '@/components/Menu/PhotoMenu.vue'
import QRCodeVue3 from "qrcode-vue3";
import { format } from "date-fns";
import { fr } from "date-fns/locale";




export default {
    name: "Menu",
    props: ['modal'],
    expose: ['cacherlemenu'],
    data() {
        return {
            menu_affiché: "",
            isFullScreen: false,
            dernierscours: [],
            cours_autosauve: [],
            dernierscoursClasse: [],
            LienMobile: "",
            chargementCours: false,
        }
    },
    mounted() {
        document.addEventListener('fullscreenchange', this.handleFullscreenChange);
        this.LienMobile = window.location.origin + "/forge-de-cours/mobile/chargement"
    },
    beforeUnmount() {
        document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
    },
    computed: {
        ...mapState(['configuration','typehorloge','éditeurs','commandeediteur','maconfig','typeConfiguration','liens','établissements','indexÉditeurEnCours','photos','chargementPhotos','tbi']),
        url() {
            return this.$route.path
        }
    },
    components: {
        PhotoMenu,
        QRCodeVue3
    },
    methods: {
        ...mapMutations(['updateTypehorloge','updateCommandeEditeur','updateEditeur']),
        ...mapActions(['RécupèreDernièresPhotos']),
        handleFullscreenChange() {
            this.isFullScreen = !!document.fullscreenElement;
        },
        toggleFullScreen() {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            } else {
                document.documentElement.requestFullscreen();
            }
        },
        enregistrer(index) {
            this.updateCommandeEditeur({commande:"enregistrer", id: index});
        },
        fermer(index) {
            this.$buefy.dialog.confirm({
                    title: 'Ne pas enregistrer',
                    message: `Voulez-vous fermer <b>sans enregistrer</b> votre cours <i>${this.éditeurs[index].cours?.titre || 'sans titre'}</i> ?`,
                    confirmText: 'Fermer sans enregitrer',
                    cancelText: 'Annuler',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.updateCommandeEditeur({commande:"fermer", id: index});
                    }
                })
        },
        clic_menu( event) {
            let parentNode = event.target;
            let parent_class = "";

            while (parentNode && parentNode.tagName !== 'HTML') {
                if ( (parentNode.tagName === 'LI' && parentNode.classList.contains('icone_menu')) ||
                     (parentNode.tagName === 'UL' && parentNode.classList.contains('menu-list'))  ||
                      parentNode.tagName === 'ASIDE') {
                        if (parentNode.tagName === 'ASIDE')
                        {
                            // Clic dans le vide : on efface
                            this.menu_affiché = ""
                            return
                        }
                    const classes = Array.from(parentNode.classList);
                    // Trouve une classe qui n'est pas dans cette liste
                    const excludedClasses = ['icone_menu', 'menu-list', 'sanssousmenu'];
                    const filteredClasses = Array.from(parentNode.classList).filter(
                        className => !excludedClasses.includes(className)
                    );
                    if (filteredClasses.length > 0) {
                        // Il y a au moins une classe qui n'est pas 'icone_menu', 'menu-list' ou 'sanssousmenu'
                        parent_class = filteredClasses[0];
                        break;
                    }
                }
                parentNode = parentNode.parentNode;
            }

            if (parent_class != "") {
                if (parent_class == this.menu_affiché)
                    this.menu_affiché = ""
                else
                    this.menu_affiché = parent_class;
            }
        },
        cacherlemenu()
        {
            this.menu_affiché = "";
        },
        possede_lien_ent() {
            let retour = false;
            for (let établissement in this.établissements)
                {
                    retour |= this.établissements[établissement]?.lien.length != 0;
                }
            return retour;
        },
        
        showTirage() {
            if (! this.éditeurs[this.indexÉditeurEnCours]?.classe_id)
                return false;

            if (this.configuration?.classes[this.éditeurs[this.indexÉditeurEnCours]?.classe_id]?.élèves)
            {
                return true;
            }
            return false;
        },
        RecalculeDerniersCours() {
            if (!this.configuration?.classes)
                return
            this.chargementCours = true
            this.dernierscours = []
            this.cours_autosauve = []
            this.dernierscoursClasse = []
            let db_résumés = new PouchDB("resumes_des_cours")
            let db_autosave = new PouchDB("autosave")
            let liste_id_editeurs = this.éditeurs.map(éditeur => éditeur.cours._id)
            
            switch (this.typeConfiguration.type)
            {
                case 'dropbox':
                    let sauvegarde = new SauvegardeCours( {cours: null, $buefy: this.$buefy, typeConfiguration: this.typeConfiguration})
                    sauvegarde.SyncRésumés_dropbox()
                    .then(() => db_résumés.allDocs({include_docs: true, descending: true}) )
                    .then( docs => {
                        this.dernierscours = docs.rows.filter(doc => doc.doc.classe !== this.configuration?.classes[this.éditeurs[this.indexÉditeurEnCours]?.classe_id]?.nom)
                        this.dernierscoursClasse = docs.rows.filter(doc => doc.doc.classe === this.configuration?.classes[this.éditeurs[this.indexÉditeurEnCours]?.classe_id]?.nom)
                        db_autosave.allDocs({include_docs: true, descending: true}).then(docs => {
                            this.cours_autosauve = docs.rows.filter(doc => ! liste_id_editeurs.includes(doc.doc._id))
                            this.chargementCours = false
                        })
                    })
                    break
                case 'navigateur':
                    db_résumés.allDocs({include_docs: true, descending: true})
                    .then( docs => {
                        this.dernierscours = docs.rows.filter(doc => doc.doc.classe !== this.configuration?.classes[this.éditeurs[this.indexÉditeurEnCours]?.classe_id]?.nom)
                        this.dernierscoursClasse = docs.rows.filter(doc => doc.doc.classe === this.configuration?.classes[this.éditeurs[this.indexÉditeurEnCours]?.classe_id]?.nom)
                        db_autosave.allDocs({include_docs: true, descending: true}).then(docs => {
                            this.cours_autosauve = docs.rows.filter(doc => ! liste_id_editeurs.includes(doc.doc._id))
                            this.chargementCours = false
                        })
                    })
                    break
                default:
                    this.chargementCours = false
            }
        },
        titre(doc) {
            if (doc.type_document === "préparation")
                return doc.titre
            else
                return format(new Date(doc.date_creation), "iii d LLL',' H'h'mm", { locale: fr })
        },
        change_editeur(index) {
            this.updateCommandeEditeur({commande:"changer", id:index})
            this.$router.push('/cours/edition');
        }
    },
    
}
</script>

<style scoped>

@import '@/components/Menu/menu.css'

</style>