import { setLineAttributes } from './stylo'
import { constrainAngle } from './ligne'
import { AjouterHistorique } from './historique'
import { getTransformedPoint } from './coordinates'
import { v4 as uuidv4 } from 'uuid'

export const fleche = (event, state, props) => {
  let isDrawing = true;
  const startPoint = getTransformedPoint(event, state.container)
  const markerId = `arrow-${uuidv4()}`
  const arrowSize = props.size * 5  // Définir la taille une seule fois
  const opacity = props.surligner ? 0.5 : 1  // Nouvelle ligne pour l'opacité

  // Utiliser le groupe principal existant
  const mainGroup = state.svg.select('g')
  const defs = state.container.querySelector('defs')

  // Créer un nouveau marqueur avec un viewBox fixe
  const marker = document.createElementNS("http://www.w3.org/2000/svg", "marker")
  marker.setAttribute("id", markerId)
  marker.setAttribute("viewBox", "0 -5 10 10")
  marker.setAttribute("refX", "0")     // Point de référence au début de la pointe
  marker.setAttribute("refY", "0")
  marker.setAttribute("markerUnits", "userSpaceOnUse") // Important: utiliser les unités de l'espace utilisateur
  marker.setAttribute("markerWidth", arrowSize)   // Taille proportionnelle à l'épaisseur du trait
  marker.setAttribute("markerHeight", arrowSize)  // Taille proportionnelle à l'épaisseur du trait
  marker.setAttribute("orient", "auto")
  marker.setAttribute("fill", props.color)
  marker.setAttribute("fill-opacity", opacity)  // Nouvelle ligne pour l'opacité

  const path = document.createElementNS("http://www.w3.org/2000/svg", "path")
  path.setAttribute("d", "M-1,-5L9,0L-1,5")  // Déplacer légèrement le début de la pointe vers la gauche
  
  marker.appendChild(path)
  defs.appendChild(marker)

  // Ajouter la ligne dans le groupe principal
  state.activeLine = mainGroup
    .append('line')
    .attr('x1', startPoint[0])
    .attr('y1', startPoint[1])
    .attr('x2', startPoint[0])
    .attr('y2', startPoint[1])
    .attr('style', setLineAttributes(props))
    .attr('marker-end', `url(#${markerId})`)

  const stopDrawing = () => {
    isDrawing = false;
  }

  const startDrawing = () => {
    isDrawing = true;
  }

  state.container.addEventListener('mouseleave', stopDrawing);
  state.container.addEventListener('mouseenter', startDrawing);

  event.on('drag', (event) => {
    if (!isDrawing) return;

    const coordinates = getTransformedPoint(event, state.container)
    const constrained = constrainAngle(
      startPoint[0],
      startPoint[1],
      coordinates[0],
      coordinates[1],
      event.sourceEvent.shiftKey
    )
    
    state.activeLine
      .attr('x2', constrained.x)
      .attr('y2', constrained.y)
  })

  event.on('end', (event) => {
    state.container.removeEventListener('mouseleave', stopDrawing);
    state.container.removeEventListener('mouseenter', startDrawing);

    const coordinates = getTransformedPoint(event, state.container)
    const constrained = constrainAngle(
      startPoint[0],
      startPoint[1],
      coordinates[0],
      coordinates[1],
      event.sourceEvent.shiftKey
    )

    state.activeLine
      .attr('x2', constrained.x)
      .attr('y2', constrained.y)
      .attr('style', setLineAttributes(props))

    AjouterHistorique(state)
  })
}
