import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { saveAs } from 'file-saver';
import b64toBlob from 'b64-to-blob';
import { svgPathBbox } from 'svg-path-bbox';

export const saveSvg = (state, props) => {
  const svgElement = state.container;
  const serializer = new XMLSerializer();
  const svgString = serializer.serializeToString(svgElement);
  const SVGrecadré = recadre(svgString);
  const base64Data = btoa(unescape(encodeURIComponent(SVGrecadré)));
  return {
    svg:`data:image/svg+xml;base64,${base64Data}`,
    uuid: props.tableauActuel.uuid, 
    historique: {undoStack: state.undoStack, redoStack: state.redoStack, currentSnapshot: state.currentSnapshot},
    paramètres: {outil: props.outil, couleur: props.color, taille: props.taille_index, surligner: props.surligner},
    image: props.tableauActuel.image};
}

export const recadre = (svgString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, 'image/svg+xml');
  const svg = doc.documentElement;
  
  // Sélectionner tous les éléments graphiques, y compris les groupes de texte
  const elements = svg.querySelectorAll('path, line, image, rect, circle, ellipse, .fdc-tbi-text-group');
  
  if (elements.length === 0) {
    return svgString;
  }

  // Initialiser les limites
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  elements.forEach(element => {
    let bbox;

    if (element.classList.contains('fdc-tbi-text-group')) {
      // Pour les groupes de texte, examiner le rect et les textes
      const rect = element.querySelector('rect');
      if (rect) {
        const x = parseFloat(rect.getAttribute('x') || '0');
        const y = parseFloat(rect.getAttribute('y') || '0');
        const width = parseFloat(rect.getAttribute('width') || '0');
        const height = parseFloat(rect.getAttribute('height') || '0');
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x + width);
        maxY = Math.max(maxY, y + height);
      }
    } else {
      switch (element.tagName.toLowerCase()) {
        case 'path':
          const d = element.getAttribute('d');
          if (d) {
            bbox = svgPathBbox(d);
            minX = Math.min(minX, bbox[0]);
            minY = Math.min(minY, bbox[1]);
            maxX = Math.max(maxX, bbox[2]);
            maxY = Math.max(maxY, bbox[3]);
          }
          break;

        case 'line':
          const x1 = parseFloat(element.getAttribute('x1') || '0');
          const y1 = parseFloat(element.getAttribute('y1') || '0');
          const x2 = parseFloat(element.getAttribute('x2') || '0');
          const y2 = parseFloat(element.getAttribute('y2') || '0');
          minX = Math.min(minX, x1, x2);
          minY = Math.min(minY, y1, y2);
          maxX = Math.max(maxX, x1, x2);
          maxY = Math.max(maxY, y1, y2);
          break;

        case 'image':
          const x = parseFloat(element.getAttribute('x') || '0');
          const y = parseFloat(element.getAttribute('y') || '0');
          const width = parseFloat(element.getAttribute('width') || '0');
          const height = parseFloat(element.getAttribute('height') || '0');
          minX = Math.min(minX, x);
          minY = Math.min(minY, y);
          maxX = Math.max(maxX, x + width);
          maxY = Math.max(maxY, y + height);
          break;

        default:
          // Pour les autres éléments, utiliser getBBox natif
          bbox = element.getBBox();
          minX = Math.min(minX, bbox.x);
          minY = Math.min(minY, bbox.y);
          maxX = Math.max(maxX, bbox.x + bbox.width);
          maxY = Math.max(maxY, bbox.y + bbox.height);
      }
    }
  });

  const width = maxX - minX;
  const height = maxY - minY;

  // Calculer les dimensions pour respecter la taille minimale de 800x800
  const minSize = 800;
  const padding = 10; // Ajout du padding
  const usableSize = minSize - (2 * padding); // Taille utilisable après padding
  const aspectRatio = width / height;

  let finalWidth, finalHeight, offsetX = padding, offsetY = padding; // Initialisation avec padding

  if (aspectRatio > 1) {
    // Plus large que haut
    finalWidth = usableSize;
    finalHeight = usableSize / aspectRatio;
    offsetY = padding + (usableSize - finalHeight) / 2;
  } else {
    // Plus haut que large
    finalHeight = usableSize;
    finalWidth = usableSize * aspectRatio;
    offsetX = padding + (usableSize - finalWidth) / 2;
  }

  // Mettre à jour le SVG pour qu'il soit carré et centré
  svg.setAttribute('width', '800');
  svg.setAttribute('height', '800');
  
  // Créer un rectangle blanc de fond
  const background = doc.createElementNS("http://www.w3.org/2000/svg", "rect");
  background.setAttribute('width', '800');
  background.setAttribute('height', '800');
  background.setAttribute('fill', 'white');
  
  // L'insérer en premier dans le SVG
  svg.insertBefore(background, svg.firstChild);
  
  // Trouver ou créer le groupe principal
  let mainGroup = svg.querySelector('g');
  if (!mainGroup) {
    mainGroup = doc.createElementNS("http://www.w3.org/2000/svg", "g");
    while (svg.firstChild) {
      mainGroup.appendChild(svg.firstChild);
    }
    svg.appendChild(mainGroup);
  }

  // Calculer l'échelle pour ajuster le contenu
  const scale = Math.min(usableSize / width, usableSize / height);

  // Appliquer la transformation pour centrer et mettre à l'échelle
  const translateX = offsetX - minX * scale;
  const translateY = offsetY - minY * scale;
  mainGroup.setAttribute('transform', `translate(${translateX} ${translateY}) scale(${scale})`);

  // Définir le viewBox final
  svg.setAttribute('viewBox', '0 0 800 800');
  
  return new XMLSerializer().serializeToString(svg);
}

export const Télécharge = (data) => {
  const imageb64 = data.svg.replaceAll('data:image/svg+xml;base64,','')
  var blob = b64toBlob(imageb64, 'image/svg+xml');
  saveAs(blob, `Tableau blanc - ${format(new Date(), "iiii d LLLL yyyy, H'h'mm", { locale: fr })}.svg`);
}