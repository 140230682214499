import { getX, getY } from './coordinates'
import { AjouterHistorique } from './historique'

export const déplacer = (event, state) => {
  const oldCursor = state.container.style.cursor
  state.container.style.cursor = 'grabbing'
  const svg = state.container
  // On utilise les coordonnées client (écran) plutôt que les coordonnées SVG transformées
  const startX = getX(event)
  const startY = getY(event)
  
  // Sauvegarder la position initiale du viewBox
  const [initX, initY, width, height] = svg.getAttribute('viewBox')?.split(' ')?.map(Number) ?? 
    [0, 0, svg.clientWidth, svg.clientHeight]

  // Calculer le facteur d'échelle actuel
  const scale = width / svg.clientWidth

  event.on('drag', (e) => {
    // Utiliser les coordonnées client pour calculer le déplacement
    const dx = (getX(e) - startX) * scale
    const dy = (getY(e) - startY) * scale
    
    // Appliquer le déplacement au viewBox
    svg.setAttribute('viewBox', `${initX - dx} ${initY - dy} ${width} ${height}`)
  })
  event.on('end', () => {
    state.container.style.cursor = oldCursor
  })
}

export const déplacegroupe = (event, groupe, state) => {
  // Vérifier si on a bien un groupe
  if (!groupe || !groupe.classList.contains('fdc-tbi-text-group')) {
    return;
  }

  let isMoving = true;
  const oldCursor = state.container.style.cursor;
  state.container.style.cursor = 'move';

  // Position initiale
  const startX = getX(event);
  const startY = getY(event);

  // Calculer le facteur d'échelle actuel
  const svg = state.container;
  const [, , width] = svg.getAttribute('viewBox')?.split(' ')?.map(Number) ?? 
    [0, 0, svg.clientWidth];
  const scale = width / svg.clientWidth;

  // Obtenir la transformation actuelle ou initialiser à 0,0
  const transform = groupe.getAttribute('transform');
  const currentTranslate = transform ? 
    transform.match(/translate\(([^)]+)\)/) : null;
  const [currentX, currentY] = currentTranslate ? 
    currentTranslate[1].split(',').map(Number) : [0, 0];

  const stopMoving = () => {
    isMoving = false;
  }

  const startMoving = () => {
    isMoving = true;
  }

  state.container.addEventListener('mouseleave', stopMoving);
  state.container.addEventListener('mouseenter', startMoving);

  event.on('drag', (e) => {
    if (!isMoving) return;

    // Calculer le déplacement en tenant compte de l'échelle
    const dx = ((getX(e) - startX) * scale);
    const dy = ((getY(e) - startY) * scale);
    
    // Appliquer la transformation
    groupe.setAttribute('transform', 
      `translate(${currentX + dx},${currentY + dy})`);
  });

  event.on('end', () => {
    state.container.removeEventListener('mouseleave', stopMoving);
    state.container.removeEventListener('mouseenter', startMoving);
    state.container.style.cursor = oldCursor;
    AjouterHistorique(state);
  });
}