import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import _ from 'lodash'
import CKEditor from '@ckeditor/ckeditor5-vue';
// Vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import 'mathquill/build/mathquill.css'
import 'mathquill/build/mathquill'
import jQuery from 'jquery'


import Vue3Tour from 'vue3-tour'
import 'vue3-tour/dist/vue3-tour.css'

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  components,
  directives,
  theme: {
    defaultTheme: 'dark',
    themes: {
        dark: {
          dark: false,
          colors: {
            primary: "#7957d5",
          },
        }
    }
  }
})

// Depuis https://github.com/Traliant/buefy-vue3
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import 'animate.css';

// Vue3Tour n'est pas dans la version dev en raison de GRANDES lenteurs
if (process.env.NODE_ENV === 'development') {
  createApp(App).use(store)
              .use(router)
               .use(Buefy)
               .use(VueAxios, axios)
               .use( CKEditor )
               .use(vuetify)
               .mount('#app')
} else if (process.env.NODE_ENV === 'production') {
  createApp(App).use(store)
               .use(router)
               .use(Buefy)
               .use(VueAxios, axios)
               .use( CKEditor )
               .use(vuetify)
               .use(Vue3Tour)
               .mount('#app')
}
