import { AjouterHistorique } from './historique'
import { getTransformedPoint } from './coordinates'

// Exporter la fonction getScale
export const getScale = (container) => {
  const [,, width] = container.getAttribute('viewBox')?.split(' ')?.map(Number) ?? 
    [0, 0, container.clientWidth];
  return width / container.clientWidth;
}

export const SauveTexte = (state) => {
  if (!state.activeTextElement) return;
  
  const textAreaNode = state.activeTextElement.select('textarea').node();
  if (!textAreaNode) return;

  const finalText = textAreaNode.value;
  
  if (finalText.length > 0) {
    const foreignObject = state.activeTextElement.node();
    const scale = getScale(state.container);
    const x = parseFloat(foreignObject.getAttribute('x')) * scale;
    const baseY = parseFloat(foreignObject.getAttribute('y')) * scale;
    // Utiliser les styles actuels du textarea
    const color = textAreaNode.style.color;
    const fontSize = parseFloat(getComputedStyle(textAreaNode).fontSize) * scale;
    const lineHeight = fontSize * 1.2;

    // Créer un groupe pour contenir les lignes de texte
    const textGroup = state.svg
      .select('g')
      .append('g')
      .attr('class', 'fdc-tbi-text-group');

    // Utiliser la largeur réelle du textarea
    const lines = finalText.split('\n');
    const textWidth = textAreaNode.offsetWidth * scale;
    
    // Ajouter le rectangle de fond avec les coordonnées ajustées
    textGroup.append('rect')
      .attr('x', x - 2)
      .attr('y', baseY + lineHeight - fontSize)
      .attr('width', textWidth + 4) // Ajouter juste le padding nécessaire
      .attr('height', lines.length * fontSize * 1.2) // Utiliser fontSize pour la hauteur exacte
      .attr('fill', '#ffffffbb')
      .attr('rx', 2);

    // Ajouter les lignes de texte avec les coordonnées ajustées
    lines.forEach((line, index) => {
      textGroup
        .append('text')
        .attr('x', x)
        .attr('y', baseY + ((index + 1) * lineHeight))
        .attr('fill', color)
        .attr('font-size', `${fontSize}px`)
        .attr('style', 'font-family: Arial')
        .text(line);
    });

    state.activeTextElement.remove();
    AjouterHistorique(state);
  } else {
    state.activeTextElement.remove();
  }

  state.tempText = '';
  state.activeTextElement = null;
}

export const adjustTextareaSize = (textarea) => {
  const isSingleLine = !textarea.value.includes('\n');
  if (isSingleLine) {
    // Pour une seule ligne, utiliser la hauteur de base
    const baseHeight = parseFloat(textarea.style.fontSize) + 4; // 4px pour le padding
    textarea.style.height = `${baseHeight}px`;
    const foreignObject = textarea.closest('foreignObject');
    if (foreignObject) {
      foreignObject.setAttribute('height', baseHeight);
    }
    return;
  }

  // Pour plusieurs lignes, utiliser le code existant
  const measurer = document.createElement('div');
  measurer.style.cssText = `
    position: absolute;
    visibility: hidden;
    height: auto;
    width: auto;
    font-size: ${textarea.style.fontSize};
    font-family: ${textarea.style.fontFamily};
    white-space: pre-wrap;
    padding: ${textarea.style.padding};
  `;
  measurer.textContent = textarea.value || 'M'; // 'M' comme largeur minimale si vide
  document.body.appendChild(measurer);

  // Mesurer et ajuster la largeur et la hauteur
  const width = measurer.offsetWidth + 15;
  textarea.style.height = 'auto';
  textarea.style.width = width + 'px';
  textarea.style.height = textarea.scrollHeight + 'px';
  
  // Ajuster le foreignObject parent
  const foreignObject = textarea.closest('foreignObject');
  if (foreignObject) {
    foreignObject.setAttribute('width', width);
    foreignObject.setAttribute('height', textarea.scrollHeight);
  }

  document.body.removeChild(measurer);
}

const applyReplacements = (text, replacements) => {
  try {
    const reps = Array.isArray(replacements) ? replacements : [];
    let result = String(text || '');
    
    for (let i = 0; i < reps.length; i++) {
      const rep = reps[i];
      if (!rep || typeof rep.from !== 'string' || typeof rep.to !== 'string') continue;
      // Utiliser replaceAll pour un remplacement littéral
      result = result.replaceAll(rep.from, rep.to);
    }
    return result;
  } catch (e) {
    return text;
  }
}

export const texte = (event, state, props, remplacements) => {
  const scale = getScale(state.container);
  const startPoint = getTransformedPoint(event, state.container);
  const baseHeight = props.size *1.1; // Hauteur de base = taille de la police + 4px de padding
  const initialWidth = props.size // Largeur initiale
  
  state.activeTextElement = state.svg
    .select('g')
    .append('foreignObject')
    .attr('class', 'fdc-tbi-text-input')
    .attr('x', startPoint[0] / scale)
    .attr('y', (startPoint[1] - props.size) / scale)
    .attr('width', initialWidth / scale)
    .attr('height', baseHeight / scale)
    .attr('transform', `scale(${scale})`);

  const textArea = state.activeTextElement
    .append('xhtml:textarea')
    .attr('class', 'text-input')
    .style('font-size', `${props.size / scale}px`)
    .style('color', props.color)
    .style('font-family', 'Arial')
    .style('padding', `${props.size/ scale *0.05}px`)
    .style('height', `${baseHeight / scale}px`)
    .style('width', `${initialWidth / scale}px`) // Utilisation de la même largeur initiale
    .style('line-height', '1')
    .style('white-space', 'nowrap');

  const textAreaNode = textArea.node();

  textAreaNode.addEventListener('input', () => {
    // Sauvegarder la position du curseur
    const cursorPosition = textAreaNode.selectionStart;
    const oldLength = textAreaNode.value.length;
    // Appliquer les remplacements
    textAreaNode.value = applyReplacements(textAreaNode.value, remplacements || []);
    
    // Ajuster la position du curseur après remplacement
    const lengthDiff = textAreaNode.value.length - oldLength;
    textAreaNode.selectionStart = textAreaNode.selectionEnd = cursorPosition + lengthDiff;

    // Conserver la largeur actuelle comme minimum
    const currentWidth = parseFloat(textAreaNode.style.width);
    const newWidth = Math.max(textAreaNode.scrollWidth, currentWidth || props.size);
    textAreaNode.style.width = newWidth + 'px';
    state.activeTextElement.attr('width', newWidth);
    
    // Si le texte contient un retour à la ligne manuel, activer le mode multi-lignes
    if (textAreaNode.value.includes('\n')) {
      textAreaNode.style.whiteSpace = 'pre-wrap';
      adjustTextareaSize(textAreaNode);
    }
  });

  textAreaNode.addEventListener('keypress', (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {  // Permettre les sauts de ligne avec Shift+Enter
      e.preventDefault();
      SauveTexte(state);
    }
  });

  // Assurer le focus après le rendu
  requestAnimationFrame(() => {
    textAreaNode.focus();
    textAreaNode.value = state.tempText || '';
    adjustTextareaSize(textAreaNode);
  });
}

export const EditeTexte = (textGroup, state) => {
  const scale = getScale(state.container);
  // Récupérer les informations du groupe existant
  const rect = textGroup.querySelector('rect');
  const texts = textGroup.querySelectorAll('text');
  const firstText = texts[0];
  
  // Récupérer les propriétés avant de supprimer les éléments
  const x = firstText.getAttribute('x');
  const baseY = parseFloat(rect.getAttribute('y'));
  const fontSize = parseFloat(firstText.getAttribute('font-size'));
  const color = firstText.getAttribute('fill');
  const lineHeight = fontSize * 1.2;
  const width = rect.getAttribute('width');
  const height = rect.getAttribute('height');

  // Récupérer la transformation existante du groupe
  const transform = textGroup.getAttribute('transform');
  let translateX = 0, translateY = 0;
  if (transform) {
    const match = transform.match(/translate\(([^,]+),([^)]+)\)/);
    if (match) {
      translateX = parseFloat(match[1]);
      translateY = parseFloat(match[2]);
    }
  }

  // Reconstruire le texte original avec les sauts de ligne
  const originalText = Array.from(texts)
    .map(text => text.textContent)
    .join('\n');

  // Supprimer immédiatement le contenu visuel du groupe
  rect.remove();
  texts.forEach(text => text.remove());

  // Créer le nouveau textarea en tenant compte de la translation
  state.activeTextElement = state.svg
    .select('g')
    .append('foreignObject')
    .attr('class', 'fdc-tbi-text-input')
    .attr('x', (parseFloat(x) + translateX) / scale)
    .attr('y', (baseY + translateY) / scale)
    .attr('width', width)
    .attr('height', height)
    .attr('transform', `scale(${scale})`);

  const textArea = state.activeTextElement
    .append('xhtml:textarea')
    .attr('class', 'text-input')
    .style('font-size', `${fontSize / scale}px`)
    .style('color', color)
    .style('font-family', 'Arial')
    .style('padding', `${2 / scale}px`)
    .style('height', `${lineHeight / scale}px`)
    .style('white-space', originalText.includes('\n') ? 'pre-wrap' : 'nowrap');

  const textAreaNode = textArea.node();

  // Gestionnaires d'événements
  textAreaNode.addEventListener('keypress', (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      textGroup.remove();
      SauveTexte(state);
    }
  });

  // Initialiser avec le texte existant et ajuster la taille
  requestAnimationFrame(() => {
    textAreaNode.value = originalText;
    textAreaNode.focus();
    textAreaNode.style.width = width + 'px'; // Définir la largeur avant adjustTextareaSize
    adjustTextareaSize(textAreaNode);
  });
};
