import axios from "axios";

export default class Dropbox {


  client_id = "qe5ztzhidun4j3z"
  client_secret = "8pvs9a8mirzrnc3"

  constructor () {
  }

  trouveredirectURI(mobile) {
    const host = window.location.host
    const protocol = window.location.protocol
    const redir_mobile = "mobile/"
    const redir_base = `${protocol}//${host}/forge-de-cours/${mobile ? redir_mobile : ''}configuration/dropbox`
    return redir_base
  }

  OuvreURLAutorisation(mobile)
  {
    let redirectURI = this.trouveredirectURI(mobile)
    const locale = "fr"
    const url_base = "https://www.dropbox.com/oauth2/authorize"
    window.location = `${url_base}?client_id=${this.client_id}&redirect_uri=${encodeURIComponent(redirectURI)}&locale=${locale}&response_type=code&token_access_type=offline`
  }

  getAccessToken() {
      if (localStorage.dropbox_access_token && localStorage.dropbox_access_token_expires - 60 > Date.now()/1000 )
        return Promise.resolve(localStorage.dropbox_access_token)
      else
      {
        const params = new URLSearchParams({
          refresh_token: localStorage.dropbox_refresh_token,
          grant_type: 'refresh_token',
          client_id: this.client_id,
          client_secret: this.client_secret
        })
        return axios.post('https://api.dropbox.com/oauth2/token', params)
        .then( (response) => {
            localStorage.setItem('dropbox_access_token', response.data.access_token)
            localStorage.setItem('dropbox_access_token_expires', response.data.expires_in + Date.now()/1000)
            return Promise.resolve(response.data.access_token)
          })
      }
    }

    /* Télécharge le fichier zip régulier /résumés.zip
      Supprime le fichier *fichier* 
      Puis envoie le fichier sur Dropbox
      */
    SupprimeRésuméInZip(fichier) {
      return this.getFileBinary("/résumés.zip")
      .then( (data) => {
        const JSZip = require('jszip');
        const zip = new JSZip();
        return zip.loadAsync(data)
      })
      .then( (zip) => {
        zip.remove(fichier)
        return zip.generateAsync({type:"base64"})
      })
      .then( (base64) => {
        return this.saveFileBase64("/résumés.zip", base64)
      })
    }
    /* Télécharge le fichier zip régulier /résumés.zip
        Ajoute / modifie le fichier *fichier* avec le contenu *contenu*
        Puis envoie le fichier sur Dropbox
      */
    EnregistreRésuméInZip(fichier, contenu) {
      const JSZip = require('jszip');
      return this.getFileBinary("/résumés.zip")
      .then( (data) => {
        const zip = new JSZip();
        return zip.loadAsync(data)
      })
      .catch((error) => {
        const zip = new JSZip();
        return zip;
      })
      .then( (zip) => {
         zip.file(fichier, JSON.stringify(contenu))
         return zip.generateAsync({type:"base64"})
      })
      .then( (base64) => {
        return this.saveFileBase64("/résumés.zip", base64)
      })
    }
    /* Télécharge le fichier zip régulier /résumés.zip
       Et retourne la liste des résumés
       */
    ListeRésumésInZip() {
      let liste_Fichiers = []
      return this.getFileBinary("/résumés.zip")
      .then( zipdata => {
        const JSZip = require('jszip');
        const zip = new JSZip();
        return zip.loadAsync(zipdata)
      })
      .then( (zip) => {
        liste_Fichiers = Object.keys(zip.files)
        return Promise.all(Object.values(zip.files).map(f => f.async('text')))
      })
      .then( alldata => {
        let fichiers = _.zipWith(liste_Fichiers, alldata, (fichier, data) => ({ fichier: fichier, data:  JSON.parse(data)}));
        return Promise.resolve(fichiers)
      })
    }
  /*
   * Télécharge tout un dossier en une seule requete et tri du plus récent au plus ancien
   * Et le tranforme en tableau de données utilisables selon le type :
   * json → objets
   * jpg → data:image/jpeg;base64, suivi des données en base 64
   * zip → n'extrait pas les fichiers
   * conserve : ne garde que les x derniers fichiers si x est un nombre >=0. Si autre valeur : ne fait rien
   */
  getZip(dossier, type, conserve) {
    return this.getAccessToken()
    .then( (token) =>
    {
      return axios.post("https://content.dropboxapi.com/2/files/download_zip", null,
      {
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'text/plain',
          'Dropbox-API-Arg': this.http_header_safe_json({path: dossier})
        }
      })
    }).then( (response => {
        if (response.data)
        {
          if (type === 'zip')
            return Promise.resolve(response.data)
          const JSZip = require('jszip');
          const zip = new JSZip();
          return zip.loadAsync(response.data)
          .then( (zip) => {
            let fichiers = _.filter(zip.files, f => !f.dir).sort((a, b) => a .date.getTime() < b.date.getTime())
            // Supprime ce qui est nécessaire…
            if (_.isInteger(conserve) && conserve > 0 && conserve < fichiers.length)
              {
                let listeSuppr =  _.takeRight(fichiers, fichiers.length - conserve)
                fichiers = fichiers.slice(0, conserve)
                if (listeSuppr.length > 0)
                  {
                    //fonction async : fera sa vie toutes seule
                    let dbx = new Dropbox()
                    dbx.deleteFiles(listeSuppr.map(f => {return { path: "/" + f.name}}))
                  }
              }

            switch (type) {
              case "json":
                return Promise.all( fichiers.map(f => f.async("text"))) 
                .then( (textes) => {
                    return Promise.resolve(textes.map((json, index) => ({fichier: '/'+fichiers[index].name, data: JSON.parse(json)})))
                  })
              case "jpg":
                return Promise.all( fichiers.map(f => f.async("base64"))) 
                .then( (bases64) => {
                    return Promise.resolve(bases64.map(img => { return "data:image/jpeg;base64,"+img}))
                  })
              }
           
          })
          .catch( (error) => {
            return Promise.reject(error)
          })
        }
        else
          return Promise.reject(response.message.response.data.error_summary)
      })

    )
    .catch( (error) => {
      if (JSON.parse(new TextDecoder('utf-8').decode(error?.response?.data || '{}'))?.error_summary.startsWith('path/not_found'))
        return Promise.resolve([])
      return Promise.reject(error)
    })
  }

  /* Liste les fichiers d'un dossier
   * Argument obligatoire : dossier
   */
  getFolder(dossier, liste, cursor) {
    return this.getAccessToken()
    .then( (token) =>
      {
        return axios.post("https://api.dropboxapi.com/2/files/list_folder", {
          "include_deleted": false,
          "include_has_explicit_shared_members": false,
          "include_media_info": false,
          "include_mounted_folders": true,
          "include_non_downloadable_files": true,
          "path": dossier,
          "recursive": false
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
      })
      .catch( (error) => {
        if (error.response.data.error.path['.tag'] == 'not_found')
          return Promise.resolve({data:{entries:[]}})
        else
          return Promise.reject(error)
      })
      .then( (response => {
        if (response.data.has_more)
          return this.getFolderContinue(response.data.entries, response.data.cursor)
        else
          return Promise.resolve(response.data.entries)
      }))
  }

  /* Poursuit le listing d'un dossier…
     Cette forction sera appelée de façon récursive si le listing n'est pas terminé
     Cette fontcion DOIT être appellée depuis getFolder()
     */
  getFolderContinue(liste, cursor) {
    return this.getAccessToken()
    .then( (token) =>
      {
        return axios.post("https://api.dropboxapi.com/2/files/list_folder/continue", {
          "cursor": cursor
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
      })
      .catch( (error) => {
          return Promise.reject(error)
      })
      .then( (response => {
        if (response.data.has_more)
          return this.getFolderContinue([...liste, ...response.data.entries], response.data.cursor)
        else
          return Promise.resolve([...liste, ...response.data.entries])
      }))
  }

  getFileText(fichier) {
    return this.getFile(fichier, "text")
  }

  getFileJson(fichier)
  {
    return this.getFile(fichier, "json")
  }

  getFileBinary(fichier)
  {
    return this.getFile(fichier, "arraybuffer")
  }

  getFile(fichier, responsetype) {
    return this.getAccessToken()
    .then( (token) =>
    {
      return axios.post("https://content.dropboxapi.com/2/files/download", null,
      {
        responseType: responsetype,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'text/plain',
          'Dropbox-API-Arg': this.http_header_safe_json({path: fichier})
        }
      })
    }).then( (response => {
        if (response.data)
            return Promise.resolve(response.data)
        else
          return Promise.reject(response.message.response.data.error_summary)
      })
    )
  }

  saveFileTexte(fichier, contenu) {
    // From https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
    function bytesToBase64(bytes) {
      const binString = Array.from(bytes, (byte) =>
        String.fromCodePoint(byte),
      ).join("");
      return btoa(binString);
    }
    return this.saveFileBase64(fichier, bytesToBase64(new TextEncoder().encode(contenu)))
  }

  saveFileBase64(fichier, base64Data) {

    return this.getAccessToken()
    .then( (token) =>
    {
      var Buffer = require('buffer').Buffer
      const binaryData = Buffer.from(base64Data, 'base64');

      return axios.post("https://content.dropboxapi.com/2/files/upload", binaryData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/octet-stream',
          'Dropbox-API-Arg': this.http_header_safe_json({autorename: false, mode: 'overwrite', mute: true, path: fichier, strict_conflict: false})
        }
      })
    })
    .catch( response => {
      return Promise.reject(response?.response?.data?.error_summary || response)
    })
  }

  /*
   fichiers : [{path: …}, {path: …}]
  */
  deleteFiles(fichiers) {
    return this.getAccessToken()
    .then( (token) =>
    {
      return axios.post("https://api.dropboxapi.com/2/files/delete_batch", {entries: fichiers},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
    })
  }

  revoke() {
      return this.getAccessToken()
      .then( (token) => axios.post("https://api.dropboxapi.com/2/auth/token/revoke", null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      }))
      .then( () => {
          localStorage.removeItem('dropbox_access_token')
          localStorage.removeItem('dropbox_access_token_expires')
          localStorage.removeItem('dropbox_refresh_token')
      })
  }

  getRefreshToken(route, mobile) {
    let redirectURI = this.trouveredirectURI(mobile)
    return new Promise((resolve, reject) => {
      if (route.query.code)
        resolve(route.query.code)
      else
        reject( {message: route.query.error, response: {data: {error_description: route.query.error_description}}})
      })
    .then(code => 
        {
          const params = new URLSearchParams({
            code: code,
            grant_type: 'authorization_code',
            redirect_uri: redirectURI,
            client_id: this.client_id, 
            client_secret: this.client_secret, 
          })
          return axios.post('https://api.dropbox.com/oauth2/token',params)
        })
      .then((response) =>
          {
            localStorage.setItem('dropbox_access_token', response.data.access_token)
            localStorage.setItem('dropbox_access_token_expires', response.data.expires_in + Date.now()/1000)
            localStorage.setItem('dropbox_refresh_token', response.data.refresh_token)
            return Promise.resolve("Connecté à Dropbox")
        })
  }

  // From https://www.dropbox.com/developers/reference/json-encoding
  // This function is simple and has OK performance compared to more
  // complicated ones: http://jsperf.com/json-escape-unicode/4
  http_header_safe_json(v) {
    var charsToEncode = /[\u007f-\uffff]/g;
    return JSON.stringify(v).replace(charsToEncode,
      function(c) {
        return '\\u'+('000'+c.charCodeAt(0).toString(16)).slice(-4);
      }
    );
  }
}
