<template>
    <div id="menu" @click="clic_menu">
        <ul class="vertical-nav">
            <!-- Fermer -->
            <li class="icone_menu sanssousmenu" title="Fermer le TBI" @click="$emit('action', {name: 'fermer'})">
                <span class="icon has-text-primary"><i class="fa-solid fa-right-from-bracket"></i></span>
            </li>
            <li :class="['icone_menu', 'sanssousmenu', {'disabled': !éditeurs.length || !tableauModifié}]" title="Insérer dans l'éditeur" @click="éditeurs.length && tableauModifié && $emit('action', {name: 'insérer'})">
                <span class="icon has-text-primary"><i class="fa-solid fa-file-import"></i></span>
            </li>
            <li :class="['icone_menu', 'sanssousmenu', {'disabled': !tableauModifié}]" title="Télécharger" @click="tableauModifié && $emit('action', {name: 'télécharger'})">
                <span class="icon has-text-primary"><i class="fa-solid fa-download"></i></span>
            </li>
            <!-- Aide -->
            <li class="icone_menu sanssousmenu" title="AideTBI" @click="$emit('action', {name: 'aide'})">
                <span class="icon has-text-primary"><i class="fa-solid fa-circle-info"></i></span>
            </li>

            <!-- Navigation dans les tableaux-->
            <li :class="['icone_menu', 'sanssousmenu', {'disabled': premierTableau}]" title="Tableau précédent" @click="!premierTableau && $emit('action', {name: 'tableau', value: 'précédent'})">
                <span class="icon has-text-primary"><i class="fa-solid fa-square-caret-left"></i></span>
            </li>
            <li :class="['icone_menu', 'sanssousmenu']" title="Tableau suivant" @click="$emit('action', {name: 'tableau', value: 'suivant'})" v-if="!dernierTableau">
                <span class="icon has-text-primary"><i class="fa-solid fa-square-caret-right"></i></span>
            </li>
            <li :class="['icone_menu', 'sanssousmenu', {'disabled': !tableauModifié}]" title="Nouveau tableau" @click="tableauModifié && $emit('action', {name: 'tableau', value: 'nouveau'})" v-else>
                <span class="icon has-text-primary"><i class="fa-solid fa-square-plus"></i></span>
            </li>
            <li :class="['icone_menu', 'sanssousmenu', {'disabled': !tableauModifié}]" title="Supprimer le tableau" @click="tableauModifié && $emit('action', {name: 'tableau', value: 'supprimer'})">
                <span class="icon has-text-primary"><i class="fa-solid fa-square-xmark"></i></span>
            </li>


            <li :class="{'selected': couleur=='#212121'}" class="icone_menu sanssousmenu" title="Noir" @click="$emit('action', {name: 'couleur', value: '#212121'})">
                <span class="icon has-text-primary"><i style="color: #212121 !important;" class="fas fa-palette"></i></span>
            </li>
            <li :class="{'selected': couleur=='#ffc93b'}" class="icone_menu sanssousmenu" title="Jaune" @click="$emit('action', {name: 'couleur', value: '#ffc93b'})">
                <span class="icon has-text-primary"><i style="color: #ffc93b !important;text-shadow: 0 0 3px #000;" class="fas fa-palette"></i></span>
            </li>
            <li :class="{'selected': couleur=='#ff555f'}" class="icone_menu sanssousmenu" title="Rouge" @click="$emit('action', {name: 'couleur', value: '#ff555f'})">
                <span class="icon has-text-primary"><i style="color: #ff555f !important;text-shadow: 0 0 3px #000;" class="fas fa-palette"></i></span>
            </li>
            <li :class="{'selected': couleur=='#3494ff'}" class="icone_menu sanssousmenu" title="Bleu" @click="$emit('action', {name: 'couleur', value: '#3494ff'})">
                <span class="icon has-text-primary"><i style="color: #3494ff !important;text-shadow: 0 0 3px #000;" class="fas fa-palette"></i></span>
            </li>
            <li :class="{'selected': couleur=='#00ff00'}" class="icone_menu sanssousmenu" title="Vert" @click="$emit('action', {name: 'couleur', value: '#00ff00'})">
                <span class="icon has-text-primary"><i style="color: #00ff00 !important;text-shadow: 0 0 3px #000;" class="fas fa-palette"></i></span>
            </li>
            <li :class="{'selected': couleur=='#ffffff'}" class="icone_menu sanssousmenu" title="Blanc" @click="$emit('action', {name: 'couleur', value: '#ffffff'})">
                <span class="icon has-text-primary"><i style="color: #ffffff !important; text-shadow: 0 0 3px #000;" class="fas fa-palette white-palette"></i></span>
            </li>
            <li :class="{'selected': surligner && ['stylo','ligne','fleche'].includes(outil), 'disabled': ['gomme','texte'].includes(outil)}" class="icone_menu sanssousmenu" title="Surligner" @click="$emit('action', {name: 'surligner'})">
                <span class="icon has-text-primary"><i class="fa-solid fa-highlighter"></i></span>
            </li>
            <!-- Outils de dessin -->
            <li :class="{'selected': outil==='déplacer'}" class="icone_menu sanssousmenu" title="déplacer" @click="$emit('action', {name: 'outil', value:'déplacer'})">
                <span class="icon has-text-primary"><i class="fa-solid fa-up-down-left-right"></i></span>
            </li>
            <li :class="{'selected': outil==='gomme'}" class="icone_menu sanssousmenu" title="Gomme" @click="$emit('action', {name: 'outil', value: 'gomme'})">
                <span class="icon has-text-primary"><i class="fas fa-eraser"></i></span>
            </li>
            <li :class="{'selected': outil==='stylo'}" class="icone_menu sanssousmenu" title="Stylo" @click="$emit('action', {name: 'outil', value: 'stylo'})">
                <span class="icon has-text-primary"><i class="fas fa-pen"></i></span>
            </li>
            
            <li :class="{'selected': outil==='ligne'}" class="icone_menu sanssousmenu" title="Ligne" @click="$emit('action', {name: 'outil', value: 'ligne'})">
                <span class="icon has-text-primary"><i class="fas fa-minus"></i></span>
            </li>
            <li :class="{'selected': outil==='fleche'}" class="icone_menu sanssousmenu" title="Flèche" @click="$emit('action', {name: 'outil', value: 'fleche'})">
                <span class="icon has-text-primary"><i class="fas fa-arrow-right"></i></span>
            </li>
            <li :class="{'selected': outil==='texte'}" class="icone_menu sanssousmenu" title="Texte" @click="$emit('action', {name: 'outil', value: 'texte'})">
                <span class="icon has-text-primary"><i class="fas fa-font"></i></span>
            </li>

            <!-- Tailles -->
            <li :class="{'selected': taille===1}" class="icone_menu sanssousmenu" title="Petit" @click="$emit('action', {name: 'taille', value: 1})">
                <span class="icon has-text-primary"><i style="font-size: 10px;" class="fas fa-circle"></i></span>
            </li>
            <li :class="{'selected': taille===2}" class="icone_menu sanssousmenu" title="Moyen" @click="$emit('action', {name: 'taille', value: 2})">
                <span class="icon has-text-primary"><i style="font-size: 15px;" class="fas fa-circle"></i></span>
            </li>
            <li :class="{'selected': taille===3}" class="icone_menu sanssousmenu" title="Gros" @click="$emit('action', {name: 'taille', value: 3})">
                <span class="icon has-text-primary"><i style="font-size: 20px;"class="fas fa-circle"></i></span>
            </li>
            <li :class="{'selected': taille===4}" class="icone_menu sanssousmenu" title="Très gros" @click="$emit('action', {name: 'taille', value: 4})">
                <span class="icon has-text-primary"><i class="fas fa-circle"></i></span>
            </li>
                        <!-- Zoom -->
                        <li class="icone_menu sanssousmenu" title="Zoom -" @click="$emit('action', {name: 'outil', value:'zoom-'})">
                <span class="icon has-text-primary"><i class="fa-solid fa-search-minus"></i></span>
            </li>
            <li class="icone_menu sanssousmenu" title="Zoom +" @click="$emit('action', {name: 'outil', value:'zoom+'})">
                <span class="icon has-text-primary"><i class="fa-solid fa-search-plus"></i></span>
            </li>
            <!-- Undo/Redo -->
            <li :class="['icone_menu', 'sanssousmenu', {'disabled': !canundo}]" title="Annuler" @click="canundo && $emit('action', {name: 'undo'})">
                <span class="icon has-text-primary"><i class="fas fa-undo"></i></span>
            </li>
            <li :class="['icone_menu', 'sanssousmenu', {'disabled': !canredo}]" title="Refaire" @click="canredo && $emit('action', {name: 'redo'})">
                <span class="icon has-text-primary"><i class="fas fa-redo"></i></span>
            </li>
        </ul>
    </div>
</template>


<script>
import { mapState } from 'vuex';

export default {
    name: "MenuTBI",
    props: {
        canredo: false,
        canundo: false,
        couleur: '',
        taille: {
            type: Number,
            default: 1
        },
        outil: {
            type: String,
            default: 'stylo'
        },
        surligner: {
            type: Boolean,
            default: false
        },
        premierTableau: {
            type: Boolean,
            default: true
        },
        dernierTableau: {
            type: Boolean,
            default: true
        },
        tableauModifié: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState(['éditeurs'])
    },
    data() {
        return {
            
        }
    },
    mounted() {
    },
}
</script>


<style scoped>

@import '@/components/Menu/menu.css';

.disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}

.disabled:hover {
    background-color: transparent !important;
}

.disabled .icon {
    color: #888 !important;
}

/* Override menu.css styles for this component */
:deep(ul.vertical-nav) {
    width: 90px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100vh;
    padding: 5px;
    justify-content: center;
    align-content: space-between;
    grid-template-rows: repeat(auto-fit, 40px);
    gap: 5px;
}

:deep(li.icone_menu) {
    width: 40px;
    height: 40px;
    margin: 0;
}


</style>